<template>
  <div v-if="visible">
    <div v-show="isloading && showWaiter" class="full-page__container">
      <b-spinner style="width: 3rem; height: 3rem" variant="light" label="loading"></b-spinner>
    </div>
    <div v-show="!isloading" class="full-page__container">
      <div class="header--static w-100">
        <b-link variant="clear" size="sm" class="button__back text-white font-weight-bold w-100" @click="onBackClick">
          <i class="las la-3x la-t-plus-2 la-arrow-circle-left mr-1"></i>
          Back
        </b-link>
        <div v-if="title" class="title font-weight-bold w-100">{{ title }}</div>
      </div>
      <iframe v-if="isContentVisible" :src="url" frameborder="0" @load="onLoad()" class="full-page__iframe"></iframe>
    </div>
  </div>
</template>

<script>
  // import { SET_IS_LOADING } from 'src/store/mutations';
  export default {
    name: 'IframeModal',
    props: {
      url: String,
      visible: Boolean,
      title: String,
      showWaiter: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    components: {},
    data() {
      return {
        isloading: true,
        loaded: false,
        isContentVisible: false,
      };
    },
    watch: {
      url(newurl, odlurl) {
        if (newurl && newurl !== odlurl) {
          //console.log('onChange');
          this.isloading = true;
          this.$nextTick(() => {
            this.showContent();
          });
        }
      },
      visible(newvisible, oldvisible) {
        if (newvisible !== oldvisible) {
          if (newvisible) this.onShow();
          else this.onHide();
        }
      },
    },
    computed: {
      show: {
        get() {
          return this.visible;
        },
      },
    },
    created() {},
    methods: {
      onBackClick() {
        let eventDetails = { url: this.url };
        this.$emit('iframe-modal-close', eventDetails);
      },
      onLoad() {
        //console.log('onLoad');
        this.isloading = false;
      },
      onShow() {
        //console.log('onShow');
        document.body.classList.add('disable-scroll');
      },
      onHide() {
        //console.log('onHide');
        document.body.classList.remove('disable-scroll');
      },
      onHidden() {
        //console.log('onHidden');
        this.isloading = false;
        let eventDetails = { presentationId: this.presentationId };
        this.$emit('abstract-modal-hidden', eventDetails);
        this.showContent();
      },
      showContent() {
        //console.log('showContent');
        this.isContentVisible = true;
      },
      hideContent() {
        //console.log('hideContent');
        this.$nextTick(() => {
          this.isContentVisible = false;
        });
      },
      showLoading() {
       //console.log('showLoading');
        this.isloading = true;
      },
      hideLoading() {
        //console.log('hideLoading');
        this.$nextTick(() => {
          this.isloading = false;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';
  @import '~@/styles/theme';

  .full-page__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .full-page__iframe {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
  }

  .header--static {
    /*position: fixed;*/
    top: 0;
    left: 0;
    text-align: left;
  }

  .button__back {
    text-align: left;
    background-color: black;
    padding: 10px 20px 0px 20px;
    height: 40px;
    display: block;
  }

  .title {
    padding: 10px 20px 10px 20px;
    background-color: #e5e5e5;
    font-size: 1.2em;
  }
</style>
