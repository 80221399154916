import { QueryHelper } from 'src/utils/query-helper';
import { OPEN_CHANNEL_MODAL } from 'src/store/actions';
import { OPEN_MEDIA_MODAL } from 'src/store/actions';

export default {
  mounted() {
    // Check if a channel is in query (live is prioritary)
    const channelFurl = this.$route.query.c;
    if (channelFurl) {
      const channelParams = QueryHelper.extractChannelParams(channelFurl);
      if (channelParams && channelParams.channelId > 0) this.$store.dispatch(OPEN_CHANNEL_MODAL, channelParams);
    } else {
      // Check if a media is in query
      const mediaFurl = this.$route.query.v;
      if (mediaFurl) {
        const mediaMetadata = QueryHelper.extractMediaMetadata(mediaFurl);
        if (mediaMetadata && mediaMetadata.id > 0) this.$store.dispatch(OPEN_MEDIA_MODAL, mediaMetadata);
      }
    }
  }
};
