<template>
  <b-toast :id="id" toaster="b-toaster-bottom-left" variant="congress" solid no-auto-hide>
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <font-awesome-icon icon="exclamation-circle" size="lg" class="la-t-plus-3 mr-2" />
        <span class="toast-title-text mr-auto">Information</span>
      </div>
    </template>

    <div class="text-left">
      A new version of the application is available.
    </div>
    <b-button block variant="congress" @click="onUpdateBtnClick" class="mt-2"> Update <i class="las la-sync la-t-plus-1"></i> </b-button>
  </b-toast>
</template>

<script>
  export default {
    name: 'NewVersionToast',
    props: {
      id: String
    },
    methods: {
      onUpdateBtnClick() {
        this.$root.$emit('updateRequested');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .toast-title-text {
    font-weight: bold;
    font-size: 1.1rem;
  }
</style>
