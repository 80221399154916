import { saveUser, destroyUser, getUser } from 'src/services/auth';
import { MediaApiService } from 'src/services/media-api';

import { LOGIN, LOGOUT, FETCH_FAVS, ADD_FAV, REM_FAV } from 'src/store/actions';
import { SET_AUTH, PURGE_AUTH, SET_FAVS, PUSH_FAV, FILTER_FAV } from 'src/store/mutations';

const user = getUser() ?? null;

const initialState = {
  loggedIn: user !== null,
  // hasAccess: null,
  user: user,
  favs: []
};

const state = { ...initialState };

const getters = {
  isLogged(state) {
    return state.loggedIn;
  },
  currentUser(state) {
    return state.user;
  },
  fullName(state) {
    return `${state.user?.firstName} ${state.user?.lastName}`;
  }
};

const actions = {
  [LOGIN]({ commit }, urlParams) {
    const escId = window.atob(urlParams.get('escId'));
    const email = window.atob(urlParams.get('email'));

    const title = window.atob(urlParams.get('title'));
    const firstName = window.atob(urlParams.get('firstName'));
    const lastName = window.atob(urlParams.get('lastName'));

    const token = urlParams.get('token');
    const expires = urlParams.get('expires');

    const user = {
      escId,
      email,
      title,
      firstName,
      lastName,
      accessToken: token,
      expires
    };

    saveUser(user);

    MediaApiService.setHeader();
    commit(SET_AUTH, user);
  },
  [LOGOUT]({ commit }) {
    destroyUser();
    commit(PURGE_AUTH);
  },
  [FETCH_FAVS]({ commit }, eventId) {
    MediaApiService.getFavorites(eventId).then(({ data }) => {
      commit(SET_FAVS, data);
    });
  },
  //eslint-disable-next-line
  [ADD_FAV]({ commit }, { eventId, digitalSessionId, sessionId, subSessionId, presentationId, personId }) {
    MediaApiService.addFavorite({ eventId, digitalSessionId, sessionId, subSessionId, presentationId, personId }).then(({ data }) => {
      commit(PUSH_FAV, data);
    });
  },
  //eslint-disable-next-line
  [REM_FAV]({ commit }, { eventId, digitalSessionId, sessionId, subSessionId, presentationId, personId }) {
    MediaApiService.removeFavorite({ eventId, digitalSessionId, sessionId, subSessionId, presentationId, personId }).then(({ data }) => {
      commit(FILTER_FAV, data);
    });
  }
};

const mutations = {
  [SET_AUTH](state, user) {
    state.loggedIn = true;
    state.user = user;
  },
  [PURGE_AUTH](state) {
    state.loggedIn = false;
    state.user = null;
  },
  /*
  [SET_HAS_ACCESS](state, hasAccess) {
    state.hasAccess = hasAccess;
  },
  */
  [SET_FAVS](state, favs) {
    state.favs = favs;
  },
  [PUSH_FAV](state, fav) {
    state.favs.push(fav);
  },
  [FILTER_FAV](state, fav) {
    state.favs = state.favs.filter(x => x.userFavoriteId !== fav.userFavoriteId);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
