import { UPDATE_PARTNER_USER } from 'src/store/actions';
import { SET_PARTNER_USER } from 'src/store/mutations';

const initialState = { partnerUser:{eescid: null}};

const state = { ...initialState };

const getters = {
    partnerUser(state) {
        return state.partnerUser;
    }
};

const actions = {
    [UPDATE_PARTNER_USER]({ commit },partnerUser) {
        commit(SET_PARTNER_USER, partnerUser);
    },
};

const mutations = {
    [SET_PARTNER_USER](state, partnerUser) {
        state.partnerUser = partnerUser;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
