<template>
    <div class="w-100">
        <div class="hero__banner">
            <b-col cols="12" class="hero__bg pt-5 px-3 px-md-0 d-flex justify-content-left" :style="{ backgroundImage: `url('/congress/img/events/${eventId}/hero-bg.png?v=${version}')` }">
                <div class="eventInfo">
                    <h1>Simulation village</h1>
                    <div class="eventDate mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet scelerisque eros, vitae iaculis nunc.</div>
                    <div class="eventDate">Please note that the programme times are displayed on local times in {{ location }}.</div>
                </div>
            </b-col>
        </div>
        <b-container fluid class="px-lg-5">
            <b-row>
                <b-col cols="12" md="8">
                    <div id="topStickyDays" class="timeLine px-lg-3">
                        <b-row class="p-2 mb-4 font-black">
                            <b-col v-for="(day, index) in eventDays" :key="day.toString()" class="date__text" :class="{ 'selected' : index === eventDaySelectedIndex}">
                                <span @click="updateTab(index)" class="d-block">{{ day | formatUnixEpocDayPart }}</span>    
                            </b-col>
                        </b-row>
                    </div>
                    <b-row no-gutters>
                        <b-col cols="12" class="px-0">
                            <b-container fluid class="mb-3">
                                <div v-for="workshop in workshops" :key="workshop.id" class="session-item mb-4">
                                    <b-row class="card session__card px-4 py-3 d-block" v-if="workshop.slots.length > 0" :class="{ 'session__card-disabled': workshop.disabled }">
                                        <b-row>
                                            <b-col class="header">
                                                <h4 class="card-title">{{ workshop.displayName }}</h4>
                                                <div class="d-block text-left lh-1">
                                                    <div class="session__type font-regular d-block d-lg-flex">
                                                        <div class="d-inline-flex align-items-center mr-3 lh-sm">
                                                            <span class="material-icons-outlined mr-1"> co_present </span> <span>Workshop</span>
                                                        </div>
                                                        <div class="d-inline-flex align-items-center mr-3 lh-sm" v-if="workshop.sessionDuration > 0">
                                                            <span class="material-icons-outlined mr-1"> access_time </span> <span>{{ workshop.sessionDuration }} min.</span>
                                                        </div>
                                                        <div class="d-inline-flex align-items-center mr-3 lh-sm" v-if="workshop.room">
                                                            <span class="material-icons-outlined mr-1"> place </span>
                                                            <span>{{ workshop.room }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" md="2" class="font-black text-md-right text-congress"> €{{ workshop.publicPrice }}/session </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <div class="session__description mt-3 font-italic">
                                                    <small>
                                                        {{ workshop.description }}
                                                    </small>
                                                </div>
                                                <div class="select__sesions mt-2">
                                                    <template v-if="workshop.purchased">
                                                        <b-badge pill variant="success" class="extended-badge-padding">You already purchased this session</b-badge>
                                                    </template>
                                                    <template v-else>
                                                        <b-button pill size="sm" v-for="slot in workshop.slots" :key="slot.id" variant="light" class="d-inline-flex align-items-center justify-content-between mb-2 mr-2 px-2" @click="selectSlot(workshop, slot)" :disabled="workshop.disabled || slot.availableSits == 0 || !isLogged || !userInfo.isRegistered">
                                                            <span class="mr-2">{{ slot.startTime.substring(0, slot.startTime.lastIndexOf(':')) }}</span> <!--<span v-html="slot.description"></span>-->
                                                            <b-form-checkbox disabled class="ml-2" :checked="slot.checked"></b-form-checkbox>
                                                        </b-button>
                                                    </template>
                                                    
                                                    <!-- <b-modal v-model="modalVisible" id="signIn__modal" hide-footer>
                                                        <div class="signIn__card position-relative p-5 text-center mb-3">
                                                            <div class="position-absolute rounded-line" style="width: 22rem; height: 22rem; top: 24rem; right: 6rem"></div>
                                                            <div class="position-absolute rounded-line" style="width: 22rem; height: 22rem; top: 0rem; right: 6rem"></div>
                                                            <div class="position-absolute rounded-line" style="width: 14rem; height: 14rem; top: 24rem; right: 6rem"></div>
                                                            <div class="position-absolute rounded-line" style="width: 14rem; height: 14rem; top: 0rem; right: 6rem"></div>
                                                            <div class="position-absolute rounded-line" style="width: 6rem; height: 6rem; top: 24rem; right: 6rem"></div>
                                                            <div class="position-absolute rounded-line" style="width: 6rem; height: 6rem; top: 0rem; right: 6rem"></div>
                                                            <div class="flex">
                                                                <div class="font-black mb-3 text-center position-relative">Sign in and join the Simulation Village</div>
                                                                <div class="mb-4 text-center position-relative">Please sign in to register to Simulation Village sessions</div>
                                                                <div>
                                                                    <b-button pill class="d-block w-100 position-relative" @click="startLoading" :href="loginUrl"> Sign in </b-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-modal> -->
                                                    <b-modal id="modal-selection-fail" hide-footer>
                                                        <div class="flex">
                                                            <div class="font-black mb-3 text-center position-relative">You already have selected or purchased slots on this session duration:</div>
                                                            <div class="mb-4 text-center position-relative" v-for="item in conflicts" :key="item.id">{{ item.name }} ({{ item.date | formatUnixEpocDayPart }})</div>
                                                            <div>
                                                                <b-button pill class="d-block w-100 position-relative" @click="$bvModal.hide('modal-selection-fail')">Close</b-button>
                                                            </div>
                                                        </div>
                                                    </b-modal>
                                                    <b-modal id="modal-check-cart" hide-footer>
                                                        <div class="flex">
                                                            <p>The following slots are no more available:</p>
                                                            <ul>
                                                                <li v-for="item in cartCheckErrors" :key="item.id">
                                                                    {{ item.name }} from {{ item.startTime.substring(0, item.startTime.lastIndexOf(':')) }} to {{ item.endTime.substring(0, item.endTime.lastIndexOf(':')) }}
                                                                </li>
                                                            </ul>
                                                            <p>Do you want to continue?</p>
                                                            <div>
                                                                <b-button pill class="d-block w-100 position-relative" @click="$bvModal.hide('modal-check-cart')">Back</b-button>
                                                                <b-button pill class="d-block w-100 position-relative" @click="redirectToCheckout">Continue</b-button>
                                                            </div>
                                                        </div>
                                                    </b-modal>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-row>
                                </div>
                            </b-container>
                        </b-col>
                    </b-row>
                </b-col>

        <b-col cols="12" md="4">
            <div class="signIn__card position-relative p-4 text-center mb-4" v-if="!isLogged">
                <div class="position-absolute rounded-line" style="width: 22rem; height: 22rem; top: 24rem; right: 6rem"></div>
                <div class="position-absolute rounded-line" style="width: 22rem; height: 22rem; top: 0rem; right: 6rem"></div>
                <div class="position-absolute rounded-line" style="width: 14rem; height: 14rem; top: 24rem; right: 6rem"></div>
                <div class="position-absolute rounded-line" style="width: 14rem; height: 14rem; top: 0rem; right: 6rem"></div>
                <div class="position-absolute rounded-line" style="width: 6rem; height: 6rem; top: 24rem; right: 6rem"></div>
                <div class="position-absolute rounded-line" style="width: 6rem; height: 6rem; top: 0rem; right: 6rem"></div>
                <div class="flex">
                    <div class="font-black mb-3 text-center position-relative">Sign in and join the Simulation Village</div>
                    <div class="mb-4 text-center position-relative">Please sign in to register to Simulation Village sessions</div>
                    <div>
                        <b-button pill class="d-block w-100 position-relative" @click="login"> Sign in </b-button>
                    </div>
                </div>
            </div>
            <RegisterPromoWidget v-else-if="!userInfo.isRegistered" />
            <!-- Cart -->
            <div class="cart__card p-4 mb-4" v-else>
                <div class="font-black mb-3">Your selection</div>
                <template v-if="slotSelection.length > 0">
                    <div class="cart__element" v-for="item in slotSelection" :key="item.id">
                        <b-row>
                            <b-col cols="10">
                                <small class="font-regular">{{ item.name }}</small>
                            </b-col>
                            <b-col cols="2" class="text-right font-bold"> €{{ item.price }} </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="grey__text">
                                <small>{{ item.date | formatUnixEpocDayPart }} • {{ item.startTime.substring(0, item.startTime.lastIndexOf(':')) }}</small>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right">
                                    <b-link @click="removeSlot(item)" class="grey__text">
                                        <span class="material-icons-outlined align-middle mr-1" style="font-size: 18px"> delete </span> <small>Remove</small>
                                    </b-link>
                                </div>
                            </b-col>
                        </b-row>
                        <div class="dotted__separator my-3"></div>
                    </div>

                    <!-- Total price -->
                    <div class="mb-3">
                        <b-row>
                        <b-col cols="10" class="font-black"> Total amount </b-col>
                        <b-col cols="2" class="text-right font-black"> €{{ slotSelection.reduce((total, item) => total + item.price, 0) }} </b-col>
                        </b-row>
                    </div>

                    <!-- Place older CTA -->
                    <div>
                        <b-button pill class="d-block w-100 position-relative btn-congress" @click="processOrder">Place older</b-button>
                    </div>
                </template>
                <template v-else>
                    <p>No session selected</p>
                </template>
            </div>
            <!-- end Cart -->

            <!-- Your Simulation Village programme -->
            <div class="cart__card p-4 mb-4" v-if="registrations.size > 0">
              <div class="font-black mb-3">Your Simulation Village programme</div>
              <b-row no-gutters>
                <div class="w-100" v-for="[key, value] of registrations" :key="key">
                    <div class="cart__date font-bold w-100 mb-2">
                        <b-badge pill variant="primary px-3 py-2">{{ new Date(key) | formatUnixEpocDayPart }}</b-badge>
                    </div>
                    <b-row v-for="item in value" :key="item.id">
                        <b-col cols="12" sm="12" md="3" lg="2" xl="1" class="timeline-time pl-xl-1 pr-xl-3 font-bold">
                            <div><small>{{ item.startTime.substring(0, item.startTime.lastIndexOf(':')) }}</small></div>
                        </b-col>
                        <b-col v-if="false" cols="1" sm="1" md="1" lg="" xl="" class="timeline-line"> </b-col>
                        <b-col cols="12" sm="12" md="9" lg="9" xl="10" class="px-0 pl-lg-3">
                            <b-container fluid class="mb-3">
                                <div class="session-item">
                                <div class="row d-block">
                            <div class="font-regular">{{ item.displayName }}</div>
                                </div>
                                </div>
                            </b-container>
                        </b-col>
                    </b-row>
                </div>
              </b-row>
            </div>
            <!-- end Your Simulation Village programme -->
          </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { DateHelper } from 'src/utils/date-helper';
    import { mapGetters, mapState } from 'vuex';
    import { MediaApiService } from 'src/services/media-api';
    import { login } from 'src/services/auth';
    import { SET_WORKSHOP_CART , SET_WORKSHOP_CART_FULL} from 'src/store/mutations';
    import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';

    export default {
        name: "SimulationVillage",
        components: {
            RegisterPromoWidget
        },
        computed: {
            ...mapGetters(['isLogged', 'currentUser']),
            ...mapState({
                eventId: state => state.app.eventId,
                startDate: state => state.app.eventStartDate,
                endDate: state => state.app.eventEndDate,
                location: state => state.app.location,
                version: state => state.app.version,
                userInfo: state => state.app.userInfo,
                slotSelection: state => state.app.workshopCart
            }),
            returnUrl() {
                return window.location.origin + this.$route.fullPath;
            },
            cartData() {
                const cartData = {
                    slots: [],
                    totalPrice: 0
                };

                const slotSelectionCopy = new Map(this.slotSelection); // Make a copy to ensure reactivity
                for (const [key, value] of slotSelectionCopy.entries()) {
                    const workshopData = this.workshops.find(x => x.id == key.split('_')[0]);
                    cartData.slots.push({
                        id: value.id,
                        workshopId: workshopData.id,
                        name: workshopData.displayName,
                        price: workshopData.publicPrice,
                        date: new Date(value.date),
                        startTime: value.startTime,
                    });
                }
                cartData.totalPrice = cartData.slots.reduce((total, item) => total + item.price, 0);
                cartData.slots.sort((a, b) => {
                    if (a.date - b.date !== 0) {
                        return a.date - b.date;
                    }
                    return a.startTime.localeCompare(b.startTime);
                });
                return cartData;
            }        
        },
        methods: {
            login() {
                login(this.returnUrl, true);
            },
            async fetchWorkshopData(date) {
                try {
                    const workshopData = (await MediaApiService.getWorkshops(this.eventId, date.toISOString().split('T')[0])).data;
                    for (const workshop of workshopData) {
                        workshop.purchased = false;
                        workshop.slots.map(slot => {
                            const selectedSlot = this.slotSelection.find(x => x.workshopId == workshop.id && x.id == slot.id);
                            slot.checked = selectedSlot != null;
                            if (!workshop.purchased && this.registrations.size > 0) {
                                workshop.purchased = this.registrations.get(slot.date)?.find(x => x.id == slot.id) != null;
                            }                                
                            return slot;
                        });
                       workshop.slots.sort((a, b) => a.startTime.localeCompare(b.startTime));
                    }
                    this.workshops = workshopData;
                } catch (err) {
                    console.error(err);
                }
            },
            async fetchUserWorkshopRegistration() {
                if (this.currentUser?.escId) {
                    try {
                        const registrationData = (await MediaApiService.getWorkshopUserSlots(this.eventId, this.currentUser.escId)).data;
                        const registrationMap = new Map();
                        for (const reg of registrationData) {
                            if (!registrationMap.has(reg.date)) {
                                registrationMap.set(reg.date, [reg]);
                            } else {
                                registrationMap.get(reg.date).push(reg);
                            }
                        }
                        const sortedMap = new Map([...registrationMap.entries()].sort((a, b) => new Date(a[0]) - new Date(b[0])));
                        for (const [, value] of sortedMap.entries()) {
                            value.sort((a, b) => a.startTime.localeCompare(b.startTime));
                        }

                        this.registrations = sortedMap;
                    } catch (err) {
                        console.error(err);
                    }
                }
            },
            async updateTab(index) {
                if (this.eventDaySelectedIndex !== index) {
                    this.eventDaySelectedIndex = index;
                    await this.fetchWorkshopData(this.eventDays[index]);
                }
            },
            selectSlot(workshop, slot) {
                if (this.hasCartSelectionTimeConflicts(workshop, slot)) {
                    return;
                }
                this.$store.commit(SET_WORKSHOP_CART, { workshopId: workshop.id, workshopName: workshop.displayName, workshopPrice: workshop.publicPrice, slotId: slot.id, slotDate: slot.date, slotStartTime: slot.startTime, slotEndTime: slot.endTime });
                workshop.slots.map(slot => {
                    const selectedSlot = this.slotSelection.find(x => x.workshopId == workshop.id && x.id == slot.id);
                    slot.checked = selectedSlot != null; 
                    return slot;
                });
            },
            removeSlot(stateItem) {
                this.$store.commit(SET_WORKSHOP_CART, { workshopId: stateItem.workshopId , workshopName: stateItem.name, workshopPrice: stateItem.price, slotId: stateItem.id, slotDate: stateItem.date, slotStartTime: stateItem.startTime, slotEndTime: stateItem.endTime });
                for (const workshop of this.workshops) {
                    workshop.slots.map(slot => {
                        const selectedSlot = this.slotSelection.find(x => x.workshopId == stateItem.workshopId && x.id == stateItem.id);
                        slot.checked = selectedSlot != null; 
                        return slot;
                    });
                }
            },
            hasCartSelectionTimeConflicts(workshop, slot) {
                const slotTimeOverlap = {
                    start: this.getTimeStringSeconds(slot.startTime),
                    end: this.getTimeStringSeconds(slot.endTime)
                };
                
                const conflictArray = [];
                const cartProducts = this.slotSelection.filter(x => x.workshopId !== workshop.id && x.date.getTime() === new Date(slot.date).getTime());
                const programmeProducts = this.registrations.get(slot.date) ?? [];

                for (const cart of cartProducts) {
                    if (this.isRangeOverlap(slotTimeOverlap.start, slotTimeOverlap.end, this.getTimeStringSeconds(cart.startTime), this.getTimeStringSeconds(cart.endTime))) {
                        conflictArray.push({ id: cart.id, name: cart.name, date: cart.date });
                    }
                }

                for (const programme of programmeProducts) {
                    if (this.isRangeOverlap(slotTimeOverlap.start, slotTimeOverlap.end, this.getTimeStringSeconds(programme.startTime), this.getTimeStringSeconds(programme.endTime))) {
                        conflictArray.push({ id: programme.id, name: programme.displayName, date: programme.date });
                    }
                }

                if (conflictArray.length > 0) {
                    this.conflicts = conflictArray;
                    this.$bvModal.show('modal-selection-fail');
                }
                return conflictArray.length > 0;
            },
            async processOrder() {
                this.cartCheckErrors = [];
                try {
                    const result = await MediaApiService.getWorkshopSlots(this.eventId, this.slotSelection.map(x => x.id));
                    const conflictItems = result.data.filter(x => x.availableSits === 0);
                    if (conflictItems.length > 0) {
                        this.cartCheckErrors = conflictItems;
                        const conflictItemIds = conflictItems.map(x => x.id);
                        this.$store.commit(SET_WORKSHOP_CART_FULL, this.slotSelection.filter(x => !conflictItemIds.includes(x.id)));
                        this.$bvModal.show('modal-check-cart');
                    } else {
                        this.redirectToCheckout();
                    }
                } catch (err) {
                    console.error(err);
                }
            },
            getTimeStringSeconds(time) {
                const timeArray = time.split(':');
                return Number(timeArray[0]) * 3600 + Number(timeArray[1]) * 60 + Number(timeArray[2]);

            },
            isRangeOverlap(xStart, xEnd, yStart, yEnd) {
                return xStart < yEnd && yStart < xEnd;
            },
            redirectToCheckout() {
                const slotIds = this.slotSelection.map(x => x.id);
                const url = `${process.env.VUE_APP_MY_PROFILE_URL}order-workshop?returnUrl=${this.returnUrl}&slotIds=${slotIds.join(',')}`;
                window.location = url;
            }
        },
        data() {
            return {
                workshops: [],
                registrations: new Map(),
                eventDays: [],
                eventDaySelectedIndex: undefined,
                conflicts: [],
                cartCheckErrors: []
            };
        },
        async mounted() {
            this.eventDays = DateHelper.getDates(DateHelper.onSiteDateTime(this.startDate), DateHelper.onSiteDateTime(this.endDate));
            try {
                const { data } = await MediaApiService.getTime();
                const serverData = DateHelper.onSiteDateTime(data);
                this.eventDaySelectedIndex = Math.max(0, this.eventDays.findIndex(x => x.toDateString() === serverData.toDateString()));
                await this.fetchUserWorkshopRegistration();
                await this.fetchWorkshopData(this.eventDays[this.eventDaySelectedIndex]);
            } catch (err) {
                console.error(err);
                this.eventDaySelectedIndex = 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~@/styles/theme';
    @import '~@/styles/theme';
    @import 'node_modules/bootstrap/scss/_functions';
    @import 'node_modules/bootstrap/scss/_variables';
    @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

    .extended-badge-padding {
        padding: 0.6rem;
    }

    .signIn__card {
        background: var(--congress);
        border-radius: 2rem;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
        color: #fff;
        overflow: hidden;
    }

    .rounded-line {
        border-radius: 9999px;
        border: 1px solid var(--congress-light);
        transform: translate(50%, -50%);
    }

    .session__card-disabled {
        background-color: #f1f2f3;
    }

    .cart__card {
        background: #fff;
        border-radius: 2rem;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
        color: #000;
        .cart__element {
            .dotted__separator {
                border-bottom: 2px dotted #e5e5e5;
                :last-of-type {
                    border-bottom: 0 !important;
                }
            }
        }
    }

    .grey__text {
        color: #5f6368;
    }

    .timeline-time {
        color: var(--congress);
        border-right: 3px solid var(--congress);
    }

    .timeline-time:after {
        display: block;
        position: absolute;
        -webkit-box-shadow: 0 0 0 5px #fff;
        box-shadow: 0 0 0 5px #fff;
        right: -0.5rem;
        background: var(--congress);
        border-radius: 50%;
        height: 14px;
        width: 14px;
        content: '';
        top: 5px;
    }

    @media (max-width: 992px) {
        .timeline-time {
        border-right: 0;
        }
        .timeline-time:after {
        display: none;
        }
    }

    @media (min-width: 992px) {
        .timeline-time .sticky__lg {
        position: sticky;
        top: 120px;
        }
    }

    @media (min-width: 1200px) {
        .timeline-time.col-lg-2 {
        max-width: fit-content;
        min-width: 4rem;
        }
    }

    .custom-checkbox .custom-control-label::before {
        border-radius: 50% !important;
    }

    ::v-deep .modal {
        backdrop-filter: blur(10px);
    }

    ::v-deep .modal-backdrop {
        background: #fff;
    }

    ::v-deep .modal-content {
        background-color: transparent;
        border: 0;
    }

    ::v-deep .modal-header {
        border: 0;
    }
</style>