export const SET_AUTH = 'setUser';
export const PURGE_AUTH = 'logOut';

// export const SET_HAS_ACCESS = 'setHasAccess';

export const SET_IS_LOADING = 'setIsLoading';

export const SET_EVENT_CONFIG = 'setEventConfig';
export const SET_BANNERS = 'setBanners';

export const SET_POOLING_ENABLED = 'setPoolingEnabled';

export const SET_TIMELINE_VISIBILITY = 'setTimelineVisibility';

export const SET_MEDIA_MODAL_VISIBILITY = 'setMediaModalVisibility';
export const SET_MEDIA_MODAL_METADATA = 'setMediaModalMetadata';
export const PURGE_MEDIA_MODAL_METADATA = 'purgeMediaModalMetadata';

export const SET_CHANNEL_MODAL_VISIBILITY = 'setChannelModalVisibility';
export const SET_CHANNEL_MODAL_PARAMS = 'setChannelModalParams';
export const PURGE_CHANNEL_MODAL_PARAMS = 'purgeChannelModalParams';

export const SET_FAVS = 'setFavs';
export const PUSH_FAV = 'pushFav';
export const FILTER_FAV = 'filterFav';

export const SET_CONGRESS_MODE = 'setCongressMode';
export const SET_CMS_CACHE_VERSION = 'setCmsCacheVersion';
export const SET_CONF_CMS_DATA = 'setConfCmsData';
export const SET_HOME_CMS_DATA = 'setHomeCmsData';

export const SET_UNREAD_NOTIFICATION = 'setUnreadNotification';

export const SET_CHAT_PARTICIPANT_CONFIG = 'setChatParticipantConfig';
export const SET_USER_INFO = 'setUserInfo';

export const SET_WORKSHOP_CART = 'setWorkshopCart';
export const SET_WORKSHOP_CART_FULL = 'setWorkshopCartFull';

export const  SET_SCROLL_POSITION = 'setScrollPosition';

export const  SET_PARTNER_USER = 'setPartnerUser';