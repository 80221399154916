<template>
  <div class="search__card">
    <div class="search__card__header">
      <div v-if="result.title ">
        <span class="search__card__header__name" v-if="result.title && result.title" v-html="result.title"></span>
        <span class="search__card__header__name" v-else-if="result.title && result.title" v-html="result.title"></span>
      </div>
    </div>
    <div class="search__card__body px-3">
      <!-- short description -->
      <template v-if="result.subtitle && result.subtitle">
        <span class="search__card__body__specialities" v-if="result.subtitle && result.subtitle" v-html="result.subtitle"> </span>
        <span class="search__card__body__specialities" v-else-if="result.subtitle && result.subtitle" v-html="result.subtitle"> </span>
      </template>
      <!-- Snippets -->
      <!-- full description -->
      <blockquote class="search__card__body__snippets pt-4" v-if="result.content && result.content.highlight">
        <span>Description: </span><span v-html="result.content"></span>
      </blockquote>
      <!-- Presentation Title -->
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.presentations && result.presentations.highlight">
        <span>Presentation: </span><span v-html="result.presentations"></span>
      </blockquote>
      <!-- Products -->
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.specialities && result.specialities.highlight">
        <span>Product: </span><span v-html="result.specialities"></span>
      </blockquote>
      <!-- Topics -->
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.topic && result.topic.highlight">
        <span>Activity: </span><span v-html="result.topic"></span>
      </blockquote>
      <!-- Speaker -->
      <!-- <blockquote class="search__card__body__snippets pt-4" v-else-if="result.data.presentations_persons && result.data.presentations_persons.snippet">
        <span>Speaker: </span> <span v-html="result.data.presentations_persons.snippet"></span>
      </blockquote> -->
      <!-- Keywords -->
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.tracks && result.tracks.highlight">
        <span>Keywords: </span><span v-html="result.tracks"></span>
      </blockquote>

      <div class="search__card__header__picture-container" style="">
        <div class="search__card__header__picture" :style="{ backgroundImage: `url('${result.thumbnail}')` }"></div>
        <!--
        <b-img v-if="result.data.photo_url && result.data.photo_url.raw" class="search__card__header__picture" :src="result.data.photo_url.raw" fluid alt="Stand's Picture"></b-img>
        -->
      </div>
    </div>

    <!-- View action -->
    <div class="search__card__footer">
      <a
        v-if="result.url && result.url"
        :href="`${result.url}`"
        class="text-uppercase search__card__footer__action"
      >
        <i class="las la-3x la-t-plus-2 la la-external-link mr-1"></i>
        Get more information
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'IndustryStandResultCard',
  components: {},
  props: {
    result: Object,
  },
  mounted() {
    // If needed...
  },
  computed: {
    ...mapState({
      eventRoute: (state) => state.app.eventRoute,
      ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      preCongressMode: (state) => state.app.preCongressMode,
      postCongressMode: (state) => state.app.postCongressMode,
    }),
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
@import '~@/styles/theme';
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.search__card {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 6px;
  background: white;
  border: solid 1px $grey-lighter;
}

.search__card__header {
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;

  font-size: 1.2rem;

  &__name {
    font-weight: bold;
  }

  &__institution {
    margin-top: 4px;
    /*font-weight: bold;*/
    display: block;
    font-size: 0.92rem;
  }

  &__picture {
    /*display: flex;*/
    /*-moz-animation-name: ;
    margin: auto;
    max-height: 100px;
    width: auto;
    padding: 10px;
    */
    /*
    max-width: 100%;
    max-height: 10rem;
    margin: auto;
    width: auto;
    height: auto;
    padding: 10px;
    */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.search__card__header__picture-container {
  background-color: #fff;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

.search__card__header__button {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 0px;
  padding: 7px;
  border-left: solid 1px $grey-lighter;
  border-bottom: solid 1px $grey-lighter;
  border-radius: 0 6px;
}

.search__card__body {
  margin-top: 22px;
  /*padding: 0 18px;*/

  &__specialities_title {
    font-size: 1rem;
    font-weight: bold;
  }
  &__specialities {
    display: block;
    font-size: 0.92rem;
  }
}

.search__card__body__snippets {
  font-size: 0.8em;
}

.search__card__footer {
  margin-top: auto;
  padding: 16px 8px 8px;
  width: 100%;
  text-align: right;
}

.search__card__footer__action {
  color: var(--congress);
  font-size: 0.9rem;
  font-weight: bold;

  &:hover,
  :focus {
    text-decoration: none;
  }
}

.search__card__body__snippets {
    font-size: 0.8em;
  }

  ::v-deep em {
    font-style: italic !important;
  }
  ::v-deep blockquote em {
    font-style: italic;
    font-weight: bold;
  }
</style>