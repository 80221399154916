export default {
    methods: {
      esc365SessionUrl(scientificSessionId) {
        return `${process.env.VUE_APP_ESC365_BASE_URL}/session/${scientificSessionId}`;
      },
      esc365PersonUrl(personId) {
        return `${process.env.VUE_APP_ESC365_BASE_URL}/person/${personId}`;
      },
      esc365PresentationUrl(scientificPresentationId) {
        return `${process.env.VUE_APP_ESC365_BASE_URL}/presentation/${scientificPresentationId}`;
      },
      esc365AbstractUrl(scientificPresentationId) {
        return `${process.env.VUE_APP_ESC365_BASE_URL}/presentation/${scientificPresentationId}`;
      }
    }
  };
  