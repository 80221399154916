<template>
  <fragment v-if="!isLoading && partnerResource.hasAccess">
    <BrightcovePartnerPlayer
      v-if="partnerResource && partnerResource.hasAccess && this.partnerResource.brightcoveAccountId && partnerResource.presentationId && partnerResource.brightcovePlayerId && this.partnerResource.videoId"
      ref="bcPlayer"
      :key="this.partnerResource.presentationId"
      :accountId="this.partnerResource.brightcoveAccountId"
      :playerId="this.partnerResource.brightcovePlayerId"
      :videoId="this.partnerResource.videoId"
      class="player-widget"
    />
    <b-container fluid v-else>
      <b-row class="content-wrapper vh-100 text-center" align-v="center">
        <b-col>
          <b-jumbotron
            header="Sorry, requested content was not found."
            lead="There could be a number of reasons for this, there may be a temporary technical issue, the page have been moved or archived, or the page address may be incorrect."
          >
          </b-jumbotron>
        </b-col>
      </b-row>
    </b-container>
  </fragment>
</template>

<script>
import { mapState } from 'vuex';
import { SET_IS_LOADING } from 'src/store/mutations';
//import { redirectErrorNotFound } from 'src/services/auth';

import BrightcovePartnerPlayer from 'src/components/Players/BrightcovePartnerPlayer';
import { MediaApiService } from 'src/services/media-api';

export default {
  name: 'PartnerMediaVideo',
  components: {
    BrightcovePartnerPlayer,
  },
  data() {
    return {
      partnerResource: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.app.isLoading,
    }),
  },
  mounted() {
    const queryString = window.location.search;
    if (queryString) {
      MediaApiService.partnerHasAccess(queryString).then(({ data }) => {
        if (data) {
          this.pushAnalytics(data);
          this.partnerResource = data;
          /*
          if (!this.partnerResource || !this.partnerResource.videoId) {
            redirectErrorNotFound();
          } else if (!this.partnerResource.hasAccess) {
            redirectErrorNotFound();
          }
          */
        }
        this.stopLoading();
      });
    } else {
      this.stopLoading();
      //redirectErrorNotFound();
    }
  },
  methods: {
    stopLoading() {
      this.$store.commit(SET_IS_LOADING, false);
    },
    pushAnalytics(data) {
      //console.log('data ' + JSON.stringify(data));
      if (data && data.hasAccess && data.escId && data.eventId) {
        //console.log('GTM PUSH');
        /* DataLayer */
        this.$gtm.push({ eventId: data.eventId });
        this.$gtm.push({ event: 'logEscId', escid: data.escId });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/theme';
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
$media-modal-bg-primary: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.8) 120%) #989898;
$media-modal-bg-secondary: #656565;
$media-modal-primary: #f2f2f2;
$media-modal-secondary: #9e9e9e;

.player-widget {
    height: 100vh;
    width: 100vw;
    position: fixed;
  }

.content-wrapper {
  background: $media-modal-bg-secondary;
  background-blend-mode: multiply, multiply;
}

.display-3 {
  font-size: 2rem;
}

.lead {
  font-size: 1rem;
}
</style>