<template>
  <fragment v-if="session.askQuestionUrl">
    <IframeModal :url="askQuestionUrl"  :title="askQuestionTitle" :visible="showModal" @iframe-modal-close="onClose()" />
    <!-- Render as button -->
    <b-button
      variant="congress"
      v-if="renderAs && renderAs === 'button'"
      class="btn-interactivity card-link text-white d-flex justify-content-center align-items-center w-100 h-100"
      @click="onAskQuestionClick"
    >
      <!-- Ask a question --> 
      Ask a question
    </b-button>

    <!-- Render as link -->
    <b-link v-else class="card-link d-flex justify-content-center align-items-center w-100 h-100" @click="onAskQuestionClick">
      <!-- Ask a question -->
      <template> Ask a question </template>
    </b-link>
  </fragment>
</template>

<script>
  //import { SET_IS_LOADING } from 'src/store/mutations';
  import IframeModal from 'src/components/Modals/IframeModal';

  export default {
    name: 'AskQuestionButton',
    props: {
      session: Object,
      forceInteract: Boolean,
      renderAs: {
        type: String,
        required: false,
        default: 'link',
        validator: function (value) {
          // The value must match one of these strings
          return value === null || ['button', 'link'].indexOf(value) !== -1;
        },
      },
    },
    components: {
      IframeModal,
    },
    data() {
      return {
        showModal: false,
        askQuestionUrl: null,
        askQuestionTitle:null
      };
    },
    computed: {},
    methods: {
      onAskQuestionClick() {
        this.askQuestionUrl = this.session.askQuestionUrl;
        this.showModal = true;
        if(this.session.title && !this.askQuestionUrl.includes('pigeonhole'))
          this.askQuestionTitle = this.session.title;
      },
      onClose() {
        this.showModal = false;
        this.askQuestionUrl = null;
        this.askQuestionTitle = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
.btn-interactivity
{
  padding: 0.5rem;
}
  </style>