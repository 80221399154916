import EventEmitter from 'events';

class SfChatBot extends EventEmitter {
  constructor(currentUser) {
    super();
    //console.log(`SF chat bot constructor...`);
    this.currentUser = currentUser;
    this.script = null;
  }

  load() {
    //console.log(`SF chat bot loading...`);
    if (!window.embedded_svc) {
      const _this = this;

      this.script = document.createElement('script');
      this.script.onload = () => {
        window.embedded_svc.settings.extraPrechatFormDetails = [
          {
            label: 'First Name',
            name: 'FirstName',
            value: _this.currentUser?.firstName ?? '',
            displayToAgent: true
          },
          {
            label: 'Last Name',
            value: _this.currentUser?.lastName ?? '',
            displayToAgent: true
          },
          {
            label: 'Email',
            value: _this.currentUser?.email ?? '',
            displayToAgent: true
          }
        ];

        window.embedded_svc.settings.prepopulatedPrechatFields = {
          FirstName:  _this.currentUser?.firstName ?? '',
          LastName: _this.currentUser?.lastName ?? '',
          Email: _this.currentUser?.email ?? '',
          Subject: 'ESC Congress Support'
        };

        window.embedded_svc.settings.extraPrechatInfo = [
          {
            entityName: 'Contact',
            showOnCreate: true,
            linkToEntityName: 'Case',
            linkToEntityField: 'ContactId',
            saveToTranscript: 'ContactId',
            entityFieldMaps: [
              {
                isExactMatch: true,
                fieldName: 'FirstName',
                doCreate: true,
                doFind: true,
                label: 'First Name'
              },
              {
                isExactMatch: true,
                fieldName: 'LastName',
                doCreate: true,
                doFind: true,
                label: 'Last Name'
              },
              {
                isExactMatch: true,
                fieldName: 'Email',
                doCreate: true,
                doFind: true,
                label: 'Email'
              }
            ]
          },
          {
            entityName: 'Case',
            showOnCreate: true,
            saveToTranscript: 'CaseId',
            entityFieldMaps: [
              {
                isExactMatch: false,
                fieldName: 'Subject',
                doCreate: true,
                doFind: false,
                label: 'issue'
              },
              {
                isExactMatch: false,
                fieldName: 'Status',
                doCreate: true,
                doFind: false,
                label: 'Status'
              },
              {
                isExactMatch: false,
                fieldName: 'Origin',
                doCreate: true,
                doFind: false,
                label: 'Origin'
              }
            ]
          }
        ];

        _this.init(); // process.env.VUE_APP_CHAT_BOT_ORGURL
      };

      this.script.setAttribute('src', 'https://service.force.com/embeddedservice/5.0/esw.min.js');

      document.head.appendChild(this.script);
    }
  }

  init(gslbBaseURL) {
    window.embedded_svc.settings.displayHelpButton = true;
    window.embedded_svc.settings.language = ''; // 'en' or 'en-US'
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
    window.embedded_svc.settings.defaultMinimizedText = 'Support chat';
    window.embedded_svc.init(
      process.env.VUE_APP_CHAT_BOT_ORGURL,
      process.env.VUE_APP_CHAT_BOT_COMMUNITYURL,
      gslbBaseURL,
      process.env.VUE_APP_CHAT_BOT_ORGID,
      process.env.VUE_APP_CHAT_BOT_LIVEAGENTNAME,
      {
        baseLiveAgentContentURL: process.env.VUE_APP_CHAT_BOT_BASELIVEAGENTCONTENTURL,
        deploymentId: process.env.VUE_APP_CHAT_BOT_DEPLOYMENTID,
        buttonId: process.env.VUE_APP_CHAT_BOT_BUTTONID,
        baseLiveAgentURL: process.env.VUE_APP_CHAT_BOT_BASELIVEAGENTURL,
        eswLiveAgentDevName: process.env.VUE_APP_CHAT_BOT_LIVEAGENTNAME,
        isOfflineSupportEnabled: true
      }
    );
  }

  dispose() {
    document.head.removeChild(this.script);
  }
}

export { SfChatBot };
