class HtmlHelper {
  static decodeHTMLEntities(text) {
    //console.log(text);
    var textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  static removeExtraBreakLines(text) {
    //console.log(text);
    return text.replace(/<BR><BR>/g, '<br>');
  }

  static isInternalLink(url) {
    return url.startsWith('/');
  }
}

export { HtmlHelper };
