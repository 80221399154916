//#region Polyfills
import 'core-js/stable';
import 'intersection-observer';
import 'regenerator-runtime/runtime';
//#endregion

import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle, faInfoCircle, faPlayCircle, faRedo, faSpinner, faSyncAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BootstrapVue } from 'bootstrap-vue';
import VueScreen from 'vue-screen';
import VueSocialSharing from 'vue-social-sharing';

import JsonUrl from 'json-url';
import observer from 'vue-mutation-observer';
import UUID from 'vue-uuid';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import VueAppInsights from 'vue-application-insights';
import VueTagManager from 'vue-tag-manager';

import VueMeta from 'vue-meta';
import LoadScript from 'vue-plugin-load-script';

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');

import { Plugin } from 'vue-fragment';

import './utils/filters.js';
//import { consoleColors } from './utils/console-color.js';

import { router } from './router';
import { store } from './store';

import App from './layout/App';

// import { AppSearchService } from './services/app-search';
import { MediaApiService } from './services/media-api';
//import { RecoApiService } from './services/reco-api';

import { CyimPlugin } from '@congress/pf-components-esc';

//import "./styles/app.scss";

/*
for (const envKey in process.env) {
  console.debug(`%cEnvironment: ${envKey}=${process.env[envKey]}`, consoleColors.misc);
}
*/

const hidePageLoader = () => {
  const loader = document.querySelector('#loader');
  loader.style.display = 'none';
};

HTMLElement.prototype.scrollIntoViewExtended = function (args) {
  //retrieve scroll to target element
  let element = this;
  if (element && element instanceof HTMLElement) {
    //default offset
    let scrollOffset = args.baseOffset;
    if (args.navOffset) {
      //add top sticky nav height to offset to avoid scrolled element under nav
      const topStickyNav = document.getElementById('topStickyNav');
      if (topStickyNav) {
        scrollOffset += topStickyNav.offsetHeight;
      }
    }
    //add top sticky days bar height to offset to avoid scrolled element under days bar
    if (args.daysBarOffset) {
      const topStickyDays = document.getElementById('topStickyDays');
      if (topStickyDays) {
        scrollOffset += topStickyDays.offsetHeight;
      }
    }
    //apply scroll offset to target element
    element.style.scrollMarginTop = `${scrollOffset}px`;
    //scroll target element into view
    element.scrollIntoView({ behavior:args.behavior??'smooth', block: args.block??'start' });
  }
};

// Initializing AppInsights from outside the Vue application
let appInsights = null;
const instrumentationKey = process.env.VUE_APP_INSIGHTS_INSTRUMENTATION_KEY;
if (instrumentationKey) {
  //console.info(`%cAppInsights: instrumentationKey=${instrumentationKey}`, consoleColors.insights);

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey
    }
  });

  appInsights = appInsights.loadAppInsights();
  appInsights.trackPageView();

  //console.info(`%cAppInsights: initialized successfully`, consoleColors.insights);
}

// Init MediaApiService
MediaApiService.init(appInsights);

// Init AppSearchService
// AppSearchService.init();

// Init RecoApiService
// RecoApiService.init(appInsights);

// Install AppInsights
if (instrumentationKey) {
  Vue.use(VueAppInsights, {
    appInsights: appInsights,
    baseName: 'MediaPlatform',
    id: instrumentationKey,
    router
  });
}

// Chat Cyim
Vue.use(CyimPlugin);

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(VueScreen, 'bootstrap');

// Install FontAwesome
library.add([faPlayCircle, faExclamationCircle, faInfoCircle, faSyncAlt, faUsers, faRedo, faSpinner]);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Install observer
Vue.use(observer);

// Instal UUID
Vue.use(UUID);

// Install VueMeta
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

/*
const metaInfo = {
  title: process.env.VUE_APP_TITLE,
  meta: [
    { name: 'description', vmid: 'description', content: process.env.VUE_APP_META_SITE_NAME },
    // OpenGraph
    { property: 'og:title', vmid: 'og:title', content: process.env.VUE_APP_META_TITLE_BASE },
    { property: 'og:description', vmid: 'og:description', content: process.env.VUE_APP_META_SITE_NAME },
    { property: 'og:site_name', vmid: 'og:site_name', content: process.env.VUE_APP_META_SITE_NAME },
    { property: 'og:card', vmid: 'og:card', content: 'summary' },
    { property: 'og:og:site', vmid: 'og:og:site', content: process.env.VUE_APP_META_SITE_NAME },
    { property: 'og:type', vmid: 'og:type', content: 'website' },
    { property: 'og:locale', vmid: 'og:locale', content: 'en_GB' },
    { property: 'fb:admins', vmid: 'fb:admins', content: process.env.VUE_APP_META_FB_ADMIN },
    { property: 'og:url', vmid: 'og:url', content: window.location.origin },
    {
      property: 'og:image:secure_url',
      vmid: 'image:secure_url',
      content: `${window.location.origin}${process.env.BASE_URL}img/sharing-1200x630.jpg`
    },
    {
      property: 'og:image',
      vmid: 'og:image',
      content: `${window.location.origin}${process.env.BASE_URL}img/sharing-1200x630.jpg`
    },
    // Twitter
    { property: 'twitter:site', vmid: 'twitter:site', content: process.env.VUE_APP_META_SITE_NAME },
    {
      property: 'twitter:description',
      vmid: 'twitter:description',
      content: process.env.VUE_APP_META_SITE_NAME
    },
    { property: 'twitter:card', vmid: 'twitter:card', content: 'summary' },
    { property: 'twitter:title', vmid: 'twitter:title', content: process.env.VUE_APP_META_TITLE_BASE },
    {
      property: 'twitter:image',
      vmid: 'twitter:image',
      content: `${window.location.origin}${process.env.BASE_URL}img/sharing-1200x630.jpg`
    }
  ]
};
*/

// Install VueSocialSharing
Vue.use(VueSocialSharing);

// Install VueTagManager
Vue.use(VueTagManager, {
  gtmId: process.env.VUE_APP_GTM_ID
});

// Install VueFragment
Vue.use(Plugin);

// Vue configuration
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['video-js'];

// JsonUrl
Vue.prototype.$lzma = JsonUrl('lzma');

// LoadScript
Vue.use(LoadScript);

// VueTour
Vue.use(VueTour);

// Hiding loader
hidePageLoader();

new Vue({
  el: '#appcontainer',
  /*
  metaInfo() {
    return metaInfo;
  },
  */
  router,
  store,
  render: h => h(App)
});
