import axios from 'axios';
import * as rax from 'retry-axios';
import qs from 'qs';

import { constants } from 'src/constants';
import { getUser, login, redirectErrorNotFound } from 'src/services/auth';

import { LOGOUT } from 'src/store/actions';

import { store } from 'src/store';

import { DateHelper } from 'src/utils/date-helper';

let axiosInstance = null;

let axiosInstanceV2 = null;


export const MediaApiService = {
  init(appInsights) {
    axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_MEDIA_API_BASE_URL
    });

    axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        if (error.response) {
          const status = error.response?.status;
          const message = error.response?.data?.message;

          if (status == 401 && message == 'Unauthorized') {
            const backUrl = window.location.href;
            console.warn('Force relogin due to unauthorized api call');

            await store.dispatch(LOGOUT);
            login(backUrl);
          }

          if (status == 400 || status == 404) {
            redirectErrorNotFound();
          }
        }
        throw error;
      }
    );

    axiosInstance.defaults.raxConfig = {
      instance: axiosInstance,

      retry: parseInt(process.env.VUE_APP_RETRY_AXIOS_RETRIES),
      noResponseRetries: parseInt(process.env.VUE_APP_RETRY_AXIOS_TIMEOUT_RETRIES),
      retryDelay: parseInt(process.env.VUE_APP_RETRY_AXIOS_DELAY),

      httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
      statusCodesToRetry: [
        [100, 199],
        [429, 429],
        [500, 599]
      ],

      backoffType: process.env.VUE_APP_RETRY_AXIOS_BACKOFF_TYPE,

      onRetryAttempt: err => {
        appInsights.trackException({ exception: new Error(err) });
      }
    };

    rax.attach(axiosInstance);

    axiosInstanceV2 = axios.create({
      baseURL: process.env.VUE_APP_MEDIA_API_BASE_URL_V2
    });

    axiosInstanceV2.interceptors.response.use(
      response => response,
      async error => {
        if (error.response) {
          const status = error.response?.status;
          const message = error.response?.data?.message;

          if (status == 401 && message == 'Unauthorized') {
            const backUrl = window.location.href;
            console.warn('Force relogin due to unauthorized api call');

            await store.dispatch(LOGOUT);
            login(backUrl);
          }

          if (status == 400 || status == 404) {
            redirectErrorNotFound();
          }
        }
        throw error;
      }
    );

    axiosInstanceV2.defaults.raxConfig = {
      instance: axiosInstanceV2,

      retry: parseInt(process.env.VUE_APP_RETRY_AXIOS_RETRIES),
      noResponseRetries: parseInt(process.env.VUE_APP_RETRY_AXIOS_TIMEOUT_RETRIES),
      retryDelay: parseInt(process.env.VUE_APP_RETRY_AXIOS_DELAY),

      httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
      statusCodesToRetry: [
        [100, 199],
        [429, 429],
        [500, 599]
      ],

      backoffType: process.env.VUE_APP_RETRY_AXIOS_BACKOFF_TYPE,

      onRetryAttempt: err => {
        appInsights.trackException({ exception: new Error(err) });
      }
    };

    rax.attach(axiosInstanceV2);
  },

  setHeader() {
    //console.log('setHeader '+getUser().accessToken);
    axiosInstance.defaults.headers.common['Authorization'] = `Token ${getUser().accessToken}`;
  },

  //#region User

  GetUserInfo(eventId) {
    return axiosInstance.get(constants.MEDIA_API_USER_INFO_ENDPOINT, {
      params: {
        eventId: eventId
      }
    });
  },

  getUserBadges() {
    return axiosInstance.get(constants.MEDIA_API_USER_BADGES_ENDPOINT);
  },

  getUserParticipantChatConfig() {
    return axiosInstance.get(constants.MEDIA_API_USER_CHAT_CONFIG_ENDPOINT);
  },

  userHasAccess(eventId) {
    return axiosInstance.post(constants.MEDIA_API_USER_HASACCESS_ENDPOINT, {
      eventId
    });
  },

  decryptEscId(escId) {
    this.setHeader();
    return axiosInstance.post(constants.MEDIA_API_USER_DECRYPT_ENDPOINT, {
      escId
    });
  },

  //#endregion

  //#region Sessions
  /*
    getAllLiveVotingNowSessions(eventId) {
      return this.getLiveSessions(eventId, 0, 0, true, false, true);
    },
  */

  getAllLiveNowSessions(eventId, votingFirst, isInteractivity,removeExternalLinks) {
    return this.getLiveSessions(eventId, 0, 0, true, false, votingFirst, isInteractivity,removeExternalLinks);
  },

  getLiveNowSessions(eventId, skip, take, votingFirst, isInteractivity,removeExternalLinks) {
    return this.getLiveSessions(eventId, skip, take, true, false, votingFirst, isInteractivity,removeExternalLinks);
  },

  getComingSessions(eventId, skip, take, coming, isInteractivity,removeExternalLinks) {
    return this.getLiveSessions(eventId, skip, take, false, coming, false, isInteractivity,removeExternalLinks);
  },

  getLiveSessions(eventId, skip, take, liveNow, coming, votingFirst, isInteractivity,removeExternalLinks) {
    //console.log("🚀 ~ file: media-api.js ~ line 113 ~ getLiveSessions ~ votingFirst", votingFirst)

    return axiosInstance.post(constants.MEDIA_API_LIVE_SESSIONS_LIST_ENDPOINT, {
      EventId: eventId,
      LiveNow: liveNow,
      Coming: coming,
      Take: take,
      Skip: skip,
      VotingFirst: votingFirst ?? false,
      Interactivity: isInteractivity ?? false,
      RemoveExternalLinks:removeExternalLinks??false
    });
  },

  getReplaySessions(eventId, skip, take, channels, days) {
    return axiosInstance.post(constants.MEDIA_API_REPLAY_SESSIONS_LIST_ENDPOINT, {
      EventId: eventId,
      Take: take,
      Skip: skip,
      Channels: channels,
      Days: days
    });
  },

  getSessionDetails(sessionId) {
    return axiosInstance.get(constants.MEDIA_API_SESSION_DETAILS_ENDPOINT.replace('{SessionId}', sessionId));
  },

  getSessionExternalLink(sessionId) {
    return axiosInstance.get(constants.MEDIA_API_SESSION_EXTERNAL_LINK_ENDPOINT.replace('{SessionId}', sessionId));
  },

  getNextSessionDetails(sessionId) {
    return axiosInstance.get(constants.MEDIA_API_NEXT_SESSION_DETAILS_ENDPOINT.replace('{SessionId}', sessionId));
  },

  getSessionDetailsByChannel(channelId) {
    return axiosInstance.get(constants.MEDIA_API_SESSION_DETAILS_BY_CHANNEL_ENDPOINT.replace('{ChannelId}', channelId));
  },

  getSessionMoreDetails(sessionId) {
    return axiosInstance.get(constants.MEDIA_API_SESSION_MORE_DETAILS_ENDPOINT.replace('{SessionId}', sessionId));
  },

  getScientificSessions(sessionId) {
    return axiosInstance.get(constants.MEDIA_API_SCIENTIFIC_SESSION_DETAILS_ENDPOINT.replace('{SessionId}', sessionId));
  },

  getSpeakerMoreDetails(eventId, userId) {
    //console.log('getSpeakerMoreDetails: ' + eventId + '-' + userId);
    return axiosInstance.post(constants.MEDIA_API_SPEAKER_MORE_DETAILS_ENDPOINT, {
      EventId: eventId,
      UserId: userId
    });
  },
  getAllSessionElasticDetails(eventId) {
    return axiosInstanceV2.get(eventId);
  },
  getAllSpeakersElasticDetails(eventId) {
    return axiosInstance.get(eventId);
  },
  /*
    getSessionsTimeLine(eventId, channelId, dateTime) {
      return axiosInstance.post(constants.MEDIA_API_SESSIONS_TIMELINE_ENDPOINT, {
        EventId: eventId,
        ChannelId: channelId,
        Datetime: dateTime
      });
    },
  */

  getTimeLineDigest(eventId, entity, channelId, dateTime) {
    //console.log('[Timeline digest] Time Zone Offset: ' + new Date().getTimezoneOffset());
    return axiosInstance.post(constants.MEDIA_API_TIMELINE_DIGEST_ENDPOINT, {
      EventId: eventId,
      Entity: entity,
      ChannelId: channelId,
      Datetime: dateTime,
      Take: 20,
      TimeZoneOffset: DateHelper.onSiteDateTime().getTimezoneOffset()
    });
  },

  getTimeLineFull(eventId, dateTime) {
    //console.log('[Timeline full] Time Zone Offset: ' + new Date().getTimezoneOffset());
    return axiosInstance.post(constants.MEDIA_API_TIMELINE_FULL_ENDPOINT, {
      EventId: eventId,
      GroupSkip: 0,
      GroupTake: 6,
      ItemSkip: 0,
      ItemTake: 1,
      Datetime: dateTime,
      TimeZoneOffset: DateHelper.onSiteDateTime().getTimezoneOffset()
    });
  },

  getTimeLineComing(eventId, itemTake, dateTime) {
    return axiosInstance.post(constants.MEDIA_API_TIMELINE_COMING_ENDPOINT, {
      EventId: eventId,
      ItemTake: itemTake,
      Datetime: dateTime
    });
  },

  getTime() {
    return axiosInstance.post(constants.MEDIA_API_TIMELINE_TIME_ENDPOINT);
  },

  getTimelineTimeToDisplay(eventId) {
    //console.log('[Time to display] Time Zone Offset: ' + new Date().getTimezoneOffset());
    return axiosInstance.post(constants.MEDIA_API_TIMELINE_TIME_TO_DISPLAY_ENDPOINT.replace('{EventId}', eventId));
  },

  //#endregion

  //#region Channels

  getChannels(eventId) {
    return axiosInstance.post(constants.MEDIA_API_CHANNELS_LIST_ENDPOINT, {
      eventId
    });
  },

  getChannelsOptions(eventId) {
    return axiosInstance.post(constants.MEDIA_API_CHANNELS_LIST_OPTIONS_ENDPOINT, {
      eventId
    });
  },

  //#endregion

  //#region Congress

  getConfig(eventRoute) {
    return axiosInstance.post(constants.MEDIA_API_CONGRESS_CONFIG_ENDPOINT, {
      EventRoute: eventRoute
    });
  },

  getBanners(eventId) {
    return axiosInstance.post(constants.MEDIA_API_CONGRESS_BANNERS_ENDPOINT.replace('{EventId}', eventId));
  },

  getDaysOptions(eventId) {
    return axiosInstance.post(constants.MEDIA_API_CONGRESS_DAYS_ENDPOINT.replace('{EventId}', eventId));
  },

  //#endregion

  //#region Workshop

  getWorkshops(eventId, date = null) {
    const params = {};
    if (date) {
      params.date = date;
    }

    return axiosInstance.get(constants.MEDIA_API_WORKSHOPS.replace('{eventId}', eventId), { params: params });
  },

  getWorkshopSlots(eventId, slotIds = [], date = null) {
    const params = {};
    if (date) {
      params.date = date;
    }
    if (slotIds != null && slotIds.length > 0) {
      params.slotIds = slotIds;
    }
    return axiosInstance.get(constants.MEDIA_API_WORKSHOP_SLOTS.replace('{eventId}', eventId), { params: params, paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })});
  },

  getWorkshopUserSlots(eventId, escId) {
    return axiosInstance.get(constants.MEDIA_API_WORKSHOP_USER_SLOTS.replace('{eventId}', eventId).replace('{escId}', escId));
  },

  //#endregion

  //#region Networking

  getCountries() {
    return axiosInstance.get(constants.MEDIA_API_NETWORKING_COUNTRIES_LIST);
  },

  getSpecialities() {
    return axiosInstance.get(constants.MEDIA_API_NETWORKING_SPECIALITIES_LIST);
  },

  /*
  getParticipant(participantId) {
    return axiosInstance.get(constants.MEDIA_API_NETWORKING_GET_PARTICIPANT.replace('{ParticipantId}', participantId));
  },
  */

  getSignature() {
    return axiosInstance.get(constants.MEDIA_API_NETWORKING_GET_SIGNATURE);
  },

  searchParticipants({ eventId, pageNumber, pageSize = 20, name = null, countries = null, specialities = null, roles = null, onlyOnline = false }) {
    return axiosInstance.post(constants.MEDIA_API_NETWORKING_PARTICIPANTS, {
      EventId: eventId,
      Name: name,
      Countries: countries,
      Specialities: specialities,
      Roles: roles,
      OnlyOnline: onlyOnline,
      PageNumber: pageNumber,
      PageSize: pageSize
    });
  },

  alterChatAgreement({ networkingChatAgreement }) {
    return axiosInstance.post(constants.MEDIA_API_NETWORKING_ALTER_CHAT_AGREEMENT, {
      NetworkingChatAgreement: networkingChatAgreement
    });
  },

  //#endregion

  //#region Favorites

  getFavorites(eventId) {
    return axiosInstance.post(constants.MEDIA_API_LIST_FAVORITES_ENDPOINT, {
      EventId: eventId
    });
  },

  addFavorite({ eventId, digitalSessionId, sessionId, subSessionId, presentationId, personId }) {
    return axiosInstance.post(constants.MEDIA_API_ADD_FAVORITE_ENDPOINT, {
      EventId: eventId,
      DigitalSessionId: digitalSessionId,
      SessionId: sessionId,
      SubSessionId: subSessionId,
      PresentationId: presentationId,
      PersonId: personId
    });
  },

  removeFavorite({ eventId, digitalSessionId, sessionId, subSessionId, presentationId, personId }) {
    return axiosInstance.post(constants.MEDIA_API_REMOVE_FAVORITE_ENDPOINT, {
      EventId: eventId,
      DigitalSessionId: digitalSessionId,
      SessionId: sessionId,
      SubSessionId: subSessionId,
      PresentationId: presentationId,
      PersonId: personId
    });
  },

  //#endregion

  //#region Presentation
  getPresentationAbstract(presentationId) {
    return axiosInstance.get(constants.MEDIA_API_PRESENTATION_ABSTRACT_ENDPOINT.replace('{PresentationId}', presentationId));
  },
  //#endregion

  //#region Partner

  partnerHasAccess(queryString) {
    return axiosInstance.post(constants.MEDIA_API_PARTNER_HASACCESS_ENDPOINT, {
      queryString
    });
  },

  partnerGetVideo(queryString) {
    return axiosInstance.post(constants.MEDIA_API_PARTNER_GETVIDEO_ENDPOINT, {
      queryString
    });
  },

  partnerGetRegistrationStatus(queryString) {
    return axiosInstance.post(constants.MEDIA_API_PARTNER_GETREGISTRATIONSTATUS_ENDPOINT, {
      queryString
    });
  },

  //#endregion

  getRecommendations(fullQuery) {
    return axiosInstance.get(fullQuery);
  }
};
