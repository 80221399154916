<template>
  <div class="px-md-4 px-3">
    <b-tabs content-class="mt-3" fill>
      <!-- Happening now -->
      <b-tab title="" :active="!postCongressMode">
        <template #title>
          <b-card-title>
            <span class="tab__title"> Happening now </span>
          </b-card-title>
        </template>
        <!-- LiveSessions Now -->
        <!-- After Congress -->
        <b-alert :show="postCongressMode" variant="congress">
          <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
          <!--
          Thank you! It was a pleasure to welcome you to {{ this.confCmsData.congress_name }}.
          You can now review all presentations on demand until {{ this.confCmsData.date_on_demand_close | formatUnixEpocDayPartWithYear }}.
          -->
          {{ this.confCmsData.postcongress_happening_now_message }}
        </b-alert>
        <!-- During Congress -->
        <div v-if="!postCongressMode">
          <SessionsBlock :eventId="this.eventId" mode="Live"></SessionsBlock>
        </div>
      </b-tab>

      <!-- Coming Next -->
      <b-tab>
        <template #title>
          <b-card-title>
            <span class="tab__title"> Coming next </span>
          </b-card-title>
        </template>
        <!-- After Congress -->
        <b-alert :show="postCongressMode" variant="congress">
          <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
          <!--
          It’s never too early to start planning.
          Put it in your agenda now: our next congress will be
          {{ this.confCmsData.date_next_congress_start | formatUnixEpocDayPart }} - {{ this.confCmsData.date_next_congress_end | formatUnixEpocDayPartWithYear }}.
          -->
          {{ this.confCmsData.postcongress_coming_next_message }}
        </b-alert>
        <!-- During Congress -->
        <div v-if="!postCongressMode">
          <ChannelSessionsBlock :eventId="this.eventId" :itemTake="4"></ChannelSessionsBlock>
        </div>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import SessionsBlock from 'src/components/Blocks/SessionsBlock';
  import ChannelSessionsBlock from 'src/components/Blocks/ChannelSessionsBlock';

  export default {
    name: 'HomeTabs',
    components: {
      SessionsBlock,
      ChannelSessionsBlock,
    },
    props: {
      eventId: Number,
    },
    data() {
      return {
        on_demand_close_date: null,
        next_congress_dates: null,
        congress_name: null,
      };
    },
    computed: {
      ...mapState({
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        postCongressMode: (state) => state.app.postCongressMode,
        confCmsData: (state) => state.app.confCmsData,
      }),
    },
    mounted() {
      // If needed...
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .alert_icon {
    color: var(--congress-dark);
  }
</style>
