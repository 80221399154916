export const constants = {
  // LocalStorage
  LOCAL_USER_KEY: 'user',
  LOCAL_TOURS_KEY: 'tours',
  LOCAL_NOTIFS_HISTORY_KEY: 'notifs',

  // MediaAPI
  MEDIA_API_USER_BADGES_ENDPOINT: 'User/Badges',
  MEDIA_API_USER_HASACCESS_ENDPOINT: 'User/HasAccess',
  MEDIA_API_USER_DECRYPT_ENDPOINT: '/User/Decrypt',
  MEDIA_API_USER_CHAT_CONFIG_ENDPOINT: 'User/ParticipantChatConfig',
  MEDIA_API_USER_INFO_ENDPOINT: 'User/userInfo',

  MEDIA_API_LIVE_SESSIONS_LIST_ENDPOINT: 'Sessions/Live',
  MEDIA_API_SESSIONS_TIMELINE_ENDPOINT: 'Sessions/TimeLine',
  MEDIA_API_REPLAY_SESSIONS_LIST_ENDPOINT: 'Sessions/Replay',
  MEDIA_API_SESSION_DETAILS_ENDPOINT: 'Sessions/{SessionId}',
  MEDIA_API_SESSION_EXTERNAL_LINK_ENDPOINT: 'Sessions/{SessionId}/ExternalLink',
  MEDIA_API_NEXT_SESSION_DETAILS_ENDPOINT: 'Sessions/{SessionId}/Next',
  MEDIA_API_SESSION_MORE_DETAILS_ENDPOINT: 'Sessions/{SessionId}/Details',
  MEDIA_API_SCIENTIFIC_SESSION_DETAILS_ENDPOINT: 'Sessions/{SessionId}/ScientificSession',
  MEDIA_API_SESSION_DETAILS_BY_CHANNEL_ENDPOINT: 'Sessions/Channels/{ChannelId}',
  MEDIA_API_ALL_SESSION_ELASTIC_ENDPOINT: 'search/search',
  MEDIA_API_SPEAKER_MORE_DETAILS_ENDPOINT: 'Persons/Details',
  MEDIA_API_PRESENTATION_ABSTRACT_ENDPOINT: 'Presentation/{PresentationId}/Abstract',
  ESC365_API_SCIENTIFIC_CONTENT_SEARCH:
    'Search/Search?fulltextquery={fulltextquery}&page={page}&eventId={eventId}&indusEventId={indusEventId}&docType={docType}&sort={sort}',
  ESC365_API_SCIENTIFIC_CONTENT_SEARCH_SPEAKERS: 'Search/Contributors?fulltextquery={fulltextquery}&page={page}&eventId={eventId}&sort={sort}',
  ESC365_API_SCIENTIFIC_CONTENT_SEARCH_PROGRAMME: 'Search/Programme?fulltextquery={fulltextquery}&page={page}&eventId={eventId}&indusEventId={indusEventId}&docType={docType}&sort={sort}',
  ESC365_API_SCIENTIFIC_CONTENT_SEARCH_FACULTY: 'Search/Faculty?fulltextquery={fulltextquery}&page={page}&eventId={eventId}&indusEventId={indusEventId}&docType={docType}&sort={sort}',
  ESC365_API_SCIENTIFIC_CONTENT_SEARCH_ABSTRACT: 'Search/Abtract?fulltextquery={fulltextquery}&page={page}&eventId={eventId}&indusEventId={indusEventId}&docType={docType}&sort={sort}',
  MEDIA_API_TIMELINE_TIME_ENDPOINT: 'TimeLine/Time',
  MEDIA_API_TIMELINE_TIME_TO_DISPLAY_ENDPOINT: 'TimeLine/{EventId}/TimeToDisplay',
  MEDIA_API_TIMELINE_DIGEST_ENDPOINT: 'TimeLine/Digest',
  MEDIA_API_TIMELINE_FULL_ENDPOINT: 'TimeLine/Full',
  MEDIA_API_TIMELINE_FULLV2_ENDPOINT: 'TimeLine/FullV2',
  MEDIA_API_TIMELINE_COMING_ENDPOINT: 'TimeLine/Coming',
  SESSION_ID_PREFIX: 'SESS',
  DIGITAL_SESSION_ID_PREFIX: 'S',
  MEDIA_API_CHANNELS_LIST_ENDPOINT: 'Channels/List',
  MEDIA_API_CHANNELS_LIST_OPTIONS_ENDPOINT: 'Channels/ListOptions',

  MEDIA_API_CONGRESS_CONFIG_ENDPOINT: 'Congress/Config',
  MEDIA_API_CONGRESS_BANNERS_ENDPOINT: 'Congress/{EventId}/Banners',
  MEDIA_API_CONGRESS_DAYS_ENDPOINT: 'Congress/{EventId}/Days',

  MEDIA_API_WORKSHOPS: 'Workshop/{eventId}',
  MEDIA_API_WORKSHOP_SLOTS: 'Workshop/{eventId}/Slots',
  MEDIA_API_WORKSHOP_USER_SLOTS: 'Workshop/{eventId}/Slots/{escId}',

  MEDIA_API_NETWORKING_COUNTRIES_LIST: 'Networking/CountriesList',
  MEDIA_API_NETWORKING_SPECIALITIES_LIST: 'Networking/SpecialitiesList',
  MEDIA_API_NETWORKING_PARTICIPANTS: 'Networking/Participants',
  MEDIA_API_NETWORKING_GET_PARTICIPANT: 'Networking/Participants/{ParticipantId}',
  MEDIA_API_NETWORKING_GET_SIGNATURE: 'Networking/Signature',
  MEDIA_API_NETWORKING_ALTER_CHAT_AGREEMENT: 'Networking/AlterChatAgreement',

  MEDIA_API_LIST_FAVORITES_ENDPOINT: 'Favorites',
  MEDIA_API_ADD_FAVORITE_ENDPOINT: 'Favorites/Add',
  MEDIA_API_REMOVE_FAVORITE_ENDPOINT: 'Favorites/Remove',

  MEDIA_API_PARTNER_HASACCESS_ENDPOINT: 'Partner/HasAccess',
  MEDIA_API_PARTNER_GETVIDEO_ENDPOINT: 'Partner/GetVideo',
  MEDIA_API_PARTNER_GETREGISTRATIONSTATUS_ENDPOINT: 'Partner/RegistrationStatus',

  // Reco API (direct call to hurence endpoint removed)
  //RECO_API_USER_RECOMMENDATIONS_ENDPOINT: 'users/{UserId}/recommendations',

  // Reco
  ESC365_API_GET_USER_RECOMMENDATIONS: 'Recommendation/UserRecommendations?v=1',
  ESC365_API_GET_ITEM_RECOMMENDATIONS: 'Recommendation/ItemRecommendations?uniqId={uniqId}',

  // Load more take
  LOAD_MORE_SESSIONS_COUNT: 8,

  // Full Timeline
  CHANNELS_COUNT_BY_SLIDE: 3,
  //CHANNELS_COUNT_BY_SLIDE_POC: 5,
  STEP_SIZE_FACTOR: 22,

  // Named Routes
  ROUTE_HOME: 'home',
  ROUTE_PROGRAMME: 'programme',
  ROUTE_FACULTY: 'Faculty',
  ROUTE_ABSTRACT: 'abstract',
  ROUTE_ONDEMAND: 'Replay',
  ROUTE_SCHEDULE: 'schedule',
  ROUTE_SESSION_DETAILS: 'sessions/:sessionFurl',
  ROUTE_VIDEO: 'video',
  ROUTE_MY_PROGRAMME: 'my-programme',
  ROUTE_SIMULATION_VILLAGE: 'simulation-village',
  ROUTE_SPEAKER_DETAILS: 'speakers/:speakerFurl',
  ROUTE_PARTNER_MEDIA_VIDEO: 'media/video',
  ROUTE_PARTNER_HEALTH: 'health',
  ROUTE_ZOOMS_LIST: 'zooms',
  ROUTE_NETWORKING_SEARCH: 'networking/search',
  ROUTE_NETWORKING_MESSAGES: 'networking/messages',
  ROUTE_NETWORKING_HOT_DISCUSSIONS: 'networking/hot-discussions',
  ROUTE_VENUE: 'venue-overview',
  ROUTE_LIVE: 'live',

  ROUTE_MOBILE_APP: 'mobile-app',
  ROUTE_MOBILE_APP_PRE_HOME: 'pre-home',
  ROUTE_MOBILE_APP_HAPPENING_NOW: 'happening-now',
  ROUTE_MOBILE_APP_INTERACTIVITY: 'interactivity',
  ROUTE_MOBILE_APP_RESOURCE: 'resource',

  // Event Location Types
  EVENT_LOCATION_ONSITE: 9601,
  EVENT_LOCATION_ONLINE: 9602,
  EVENT_LOCATION_HYBRID: 9603,

  HF_2022_EVENT_ID: 986,
  ACNAP_2022_EVENT_ID: 995,
  ESC_2022_EVENT_ID: 991,
  ESC_ASIA_2022_EVENT_ID: 1243,
  ACVC_2023_EVENT_ID: 1274,
  EHRA_2023_EVENT_ID: 1256,
  EACVI_2023_EVENT_ID: 1304,
  EAPC_2023_EVENT_ID: 1327,
  HF_2023_EVENT_ID: 1322,
  ACNAP_2023_EVENT_ID: 1546,
  ESC_2023_EVENT_ID: 1334,
  ACVC_2024_EVENT_ID: 2078,
  EHRA_2024_EVENT_ID: 2102,
  PREV_2024_EVENT_ID: 2113,
  ESC_2024_EVENT_ID: 2334,
  HF_2024_EVENT_ID: 2152,
  EE_2024_EVENT_ID: 2491,
  ICNC_2024_EVENT_ID: 2362,

  //Default Disclaimer
  INDUSTRY_DEFAULT_DISCLAIMER:
    'This industry organised session does not necessarily reflect the opinion of the European Society of Cardiology. Please be aware that your profile information and details will be shared with the sponsor of the Industry Session you choose to view. When accessing an Industry Session, the following data will be shared with the corresponding industry: title, first name, family name, age, gender, country, state/province, email address and profile information.',

  //Download mobile app meta
  NO_BANNER_SMART_BANNER_META: [{ name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'none' }],
  EE_2024_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Berlin?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the EuroEcho 2024 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/2491/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/2491/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/de/app/euroecho-imaging-2024/id6736407375',
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.EuroEchoImaging2024',
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/esc-congress' },
  ],
  /*
  ESC_2024_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in London?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ESC Congress 2024 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/2334/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/2334/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/esc-congress-2024/id6504292494',
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.esccongress2024',
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/esc-congress' },
  ],
  ICNC_2024_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Seville ?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ICNC-CT 2024' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: ' mobile app so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/2362/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/2362/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/icnc-ct-2024/id6494986924'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.icncct2024'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/ICNC' }
  ]
    */
  /*
  PREV_2024_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Athens?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ESC Preventive Cardiology 2024' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: ' mobile app so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/2113/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/2113/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/esc-preventive-cardiology-2024/id6479883588'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.preventive2024'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/Preventive-Cardiology' }
  ],
  HF2024_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Lisbon?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the Heart Failure 2024' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: ' mobile app so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/2152/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/2152/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/heart-failure-2024/id6494985662'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.heartfailure2024'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/Heart-Failure' }
  ],
    EHRA_2024_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Berlin?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the EHRA 2024 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/2102/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/2102/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/fr/app/ehra-2024/id6479236354'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.ehra2024'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/EHRA-Congress' }
  ],
  ACVC_2024_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Athens?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ACVC 2024 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/2078/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/2078/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/acvc-2024/id6477709473'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.escacute2024'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/Acute-Cardiovascular-Care' }
  ],
  ESC_2023_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Amsterdam?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ESC Congress 2023 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/1334/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/1334/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/esc-congress-2023/id6450368156'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.esccongress2023'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/esc-congress' }
  ],
  ACNAP_2023_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Join us in Edinburgd?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ACNAP 2023 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/1546/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/1546/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/acnap-2023/id6449545158'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.acnapesc2023'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/ACNAP-Congress' }
  ],
  HF_2022_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Madrid?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the Heart Failure 2022 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/986/app-logo.jpg` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/986/app-logo.jpg` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/heart-failure-2022/id1623594728'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.heartFailure2022'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/Heart-Failure' }
  ],
  ACNAP_2022_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Madrid?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ACNAP-EuroHeartCare 2022' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'mobile app so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/995/app-logo.jpg` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/995/app-logo.jpg` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/acnap-euroheartcare-2022/id1623858220'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.acnapehc2022'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/EuroHeartCare' }
  ],
  ESC_2022_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Barcelona?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ESC Congress 2022 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/991/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/991/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/app/esc-congress-2022/id1640096273'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.esccongress'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/esc-congress' }
  ],
  ACVC2023_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Marseille?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ESC ACVC 2023 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/1274/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/1274/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/esc-acute-cardiovascular-care/id1673779146'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.escacute'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/acute-cardiovascular-care' }
  ],
  EAPC2023_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Malaga?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the ESC Preventive Cardiology 2023' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'mobile app so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/1327/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/1327/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/esc-preventive-cardiology-2023/id6446951528'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.preventive2023'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/Preventive-Cardiology' }
  ],
  EHRA2023_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Barcelona?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the EHRA 2023 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/1256/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/1256/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/fr/app/ehra-2023/id6446836654'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.ehra2023'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/EHRA-Congress' }
  ],
  EACVI2023_SMART_BANNER_META: [
    { name: 'smartbanner:title', vmid: 'smartbanner:title', content: 'Joining us in Barcelona?' },
    { name: 'smartbanner:author', vmid: 'smartbanner:author', content: 'Download the EACVI 2023 mobile app' },
    { name: 'smartbanner:price', vmid: 'smartbanner:price', content: 'so that you have the schedule to hand' },
    { name: 'smartbanner:price-suffix-apple', vmid: 'smartbanner:price-suffix-apple', content: '.' },
    { name: 'smartbanner:price-suffix-google', vmid: 'smartbanner:price-suffix-google', content: '.' },
    { name: 'smartbanner:icon-apple', vmid: 'smartbanner:icon-apple', content: `${process.env.BASE_URL}img/events/1304/app-logo.png` },
    { name: 'smartbanner:icon-google', vmid: 'smartbanner:icon-google', content: `${process.env.BASE_URL}img/events/1304/app-logo.png` },
    { name: 'smartbanner:button', vmid: 'smartbanner:button', content: 'Download' },
    {
      name: 'smartbanner:button-url-apple',
      vmid: 'smartbanner:button-url-apple',
      content: 'https://apps.apple.com/us/app/eacvi-2023/id6448187426'
    },
    {
      name: 'smartbanner:button-url-google',
      vmid: 'smartbanner:button-url-google',
      content: 'https://play.google.com/store/apps/details?id=com.shapper.eacvi2023'
    },
    { name: 'smartbanner:enabled-platforms', vmid: 'smartbanner:enabled-platforms', content: 'android,ios' },
    { name: 'smartbanner:close-label', vmid: 'smartbanner:close-label', content: 'Close' },
    { name: 'smartbanner:api', vmid: 'smartbanner:api', content: 'false' },
    { name: 'smartbanner:hide-path', vmid: 'smartbanner:hide-path', content: '/EHRA-Congress' }
  ]
  */
};
