<template>
  <div>
    <b-jumbotron
      header="Access restricted"
      lead=" This feature is restricted to delegates with the full ESC Congress 2021 - The Digital Experience registration."
      class="center"
    >
      <b-button variant="congress" @click="register">
        <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
        Register 
      </b-button>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { register } from 'src/services/auth';

export default {
  name: 'NetworkingNoAccessWidget',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      eventRoute: (state) => state.app.eventRoute,
      eventId: (state) => state.app.eventId,
      networkingChatAgreement: (state) => state.app.chatParticipantConfig.networkingChatAgreement,
    }),
  },
  async mounted() {},
  methods: {
     register() {
      register(this.returnUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';

@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

@include media-breakpoint-up(sm) {
  .jumbotron {
    padding: 1.5rem 4rem;
    text-align: center;
  }
}

.display-3 {
  font-size: 2rem;
}

.lead {
  font-size: 1rem;
}
</style>
