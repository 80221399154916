<template>
  <div>
    <!-- Happening now -->
    <div>
      <Interactivity mode="Live" :votingFirst="false" :Ismobile="true"></Interactivity>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Interactivity from 'src/components/Blocks/Interactivity';

  export default {
    name: 'MobileAppInteractivity',
    components: {
      Interactivity,
    },
    data() {
      return {};
    },
    computed: {
      ...mapState({
        eventId: (state) => state.app.eventId,
      }),
    },
    mounted() {},
    methods: {},
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';
</style>
