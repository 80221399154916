import StoryblokClient from 'storyblok-js-client/dist/es5/index.es';
import { store } from 'src/store';
import crypto from 'crypto'
import { QueryHelper } from 'src/utils/query-helper';

export function getStoryBlokPreviewToken() {
  const storyBlokPreviewToken = process.env.VUE_APP_STORYBLOK_PREVIEW_TOKEN;
  return storyBlokPreviewToken;
}

export function getStoryBlokClient() {
  const cmsEndpoint = store.state.app.cmsEndpoint;

  return new StoryblokClient(
    {
      accessToken: getStoryBlokPreviewToken(),
      cache: {
        clear: 'auto',
        type: 'memory'
      }
    },
    cmsEndpoint
  );
}

export function getStoryBlokBridge() {
  //eslint-disable-next-line
  return new StoryblokBridge();
}

export function isInStoryBlokEditorPreview() {
  let result = false;
  // getQueryParam = access requests URL param by name 
  let validationString
    = QueryHelper.getParameterByName('_storyblok_tk%5Bspace_id%5D')
    + ':'
    + getStoryBlokPreviewToken()
    + ':'
    + QueryHelper.getParameterByName('_storyblok_tk%5Btimestamp%5D')
  let validationToken = crypto.createHash('sha1').update(validationString).digest('hex')

  //console.log('validationString ' + validationString);
  //console.log('validationToken ' + validationToken);

  if (QueryHelper.getParameterByName('_storyblok_tk%5Btoken%5D') == validationToken /*&&
    QueryHelper.getParameterByName('_storyblok_tk%5Btimestamp%5D') > Math.floor(Date.now() / 1000) - 3600*/) {
    // you're in the edit mode.
    result = true
  }
  return result;
}
