import { constants } from 'src/constants';
import { MediaApiService } from 'src/services/media-api';
import { FETCH_BANNERS, TOGGLE_TIMELINE } from 'src/store/actions';
import {
  SET_BANNERS, SET_CHAT_PARTICIPANT_CONFIG, SET_CMS_CACHE_VERSION, SET_CONF_CMS_DATA, SET_CONGRESS_MODE, SET_EVENT_CONFIG, SET_HOME_CMS_DATA, SET_IS_LOADING, SET_TIMELINE_VISIBILITY, SET_UNREAD_NOTIFICATION,
  SET_USER_INFO, SET_WORKSHOP_CART, SET_WORKSHOP_CART_FULL
} from 'src/store/mutations';

const initialState = {
  version: process.env.PACKAGE_VERSION || 'Unknown',
  isLoading: true,
  timelineExpanded: false,
  eventId: -1,
  eventStartDate: null,
  eventEndDate: null,
  eventRoute: null,
  eventBaseTitle: null,
  isInPreviewMode: false,
  preCongressMode: false,
  postCongressMode: false,
  ressourcesMovedTo365: false,
  brightcoveAccountId: null,
  brightcovePlayerId: null,
  registrationLink: null,
  cmsEndpoint: process.env.VUE_APP_STORYBLOK_DEFAULT_ENDPOINT,
  escBanners: [],
  cmsCacheVersion: 0,
  confCmsData: {},
  homeCmsData: {},
  widgetsParameters: null,
  industryStandsSearch: false,
  indexAbstracts: false,
  indexIndustries: false,
  indexLiveSessions: false,
  indexOnDemandSessions: false,
  indexSpeakers: false,
  locationTypeId: null,
  locationCity: null,
  showLiveBadge: null,
  showChannelOnline: null,
  location: null,
  unreadNotifications: 0,
  chatParticipantConfig: {},
  locationType: null,
  organiserName: null,
  eventName: null,
  userInfo: {},
  workshopCart: []
};

const state = { ...initialState };

const getters = {
  appVersion(state) {
    return state.version;
  },
  isTimelineExpanded(state) {
    return state.timelineExpanded;
  }
};

const actions = {
  [FETCH_BANNERS]({ commit, state }) {
    const eventId = state.eventId;
    if (!eventId || eventId <= 0) return;

    MediaApiService.getBanners(eventId).then(({ data }) => {
      commit(SET_BANNERS, data);
    });
  },
  [TOGGLE_TIMELINE]({ commit, state }) {
    commit(SET_TIMELINE_VISIBILITY, !state.timelineExpanded);
  }
};

const mutations = {
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_EVENT_CONFIG](
    state,
    {
      eventId,
      escEventId,
      eventRoute,
      eventBaseTitle,
      eventStartDate,
      eventEndDate,
      preCongressMode,
      postCongressMode,
      ressourcesMovedTo365,
      brightcoveAccountId,
      brightcovePlayerId,
      registrationLink,
      cmsEndpoint,
      cmsCacheVersion,
      widgetsParameters,
      industryStandsSearch,
      indexAbstracts,
      indexIndustries,
      indexLiveSessions,
      indexOnDemandSessions,
      indexSpeakers,
      locationTypeId,
      location,
      locationCity,
      locationType,
      organiserName,
      eventName,
    }
  ) {
    state.eventId = eventId;
    state.escEventId = escEventId;
    state.eventRoute = eventRoute;
    state.eventBaseTitle = eventBaseTitle;
    state.eventStartDate = eventStartDate;
    state.eventEndDate = eventEndDate;
    state.preCongressMode = preCongressMode;
    state.postCongressMode = postCongressMode;
    state.ressourcesMovedTo365 = ressourcesMovedTo365;
    state.brightcoveAccountId = brightcoveAccountId;
    state.brightcovePlayerId = brightcovePlayerId;
    state.registrationLink = registrationLink;
    if (cmsEndpoint) state.cmsEndpoint = cmsEndpoint;
    if (cmsCacheVersion) state.cmsCacheVersion = cmsCacheVersion;
    state.widgetsParameters = widgetsParameters;
    state.industryStandsSearch = industryStandsSearch;
    state.indexAbstracts = indexAbstracts;
    state.indexIndustries = indexIndustries;
    state.indexLiveSessions = indexLiveSessions;
    state.indexOnDemandSessions = indexOnDemandSessions;
    state.indexSpeakers = indexSpeakers;
    state.locationTypeId = locationTypeId;
    state.location = location;
    state.locationCity = locationCity;
    state.locationEvent = location?.split('&')[0]?.trim()
    state.locationType = locationType,
    state.organiserName = organiserName,
    state.eventName = eventName
    if (locationTypeId) {
      switch (locationTypeId) {
        case constants.EVENT_LOCATION_ONLINE:
          state.showLiveBadge = true
          state.showChannelOnline = true
          break
        case constants.EVENT_LOCATION_ONSITE:
        case constants.EVENT_LOCATION_HYBRID:
        default:
          state.showLiveBadge = false
          state.showChannelOnline = false
          break
      }
    }

  },
  [SET_BANNERS](state, banners) {
    state.escBanners = banners?.top;
    state.industryBanners = banners?.bottom;
  },
  [SET_TIMELINE_VISIBILITY](state, visibility) {
    state.timelineExpanded = visibility;
  },
  [SET_HOME_CMS_DATA](state, homeCmsData) {
    state.homeCmsData = homeCmsData;
  },
  [SET_CONGRESS_MODE](state, { preCongressMode, postCongressMode }) {
    state.isInPreviewMode = true;

    state.preCongressMode = preCongressMode;
    state.postCongressMode = postCongressMode;
  },
  [SET_CONF_CMS_DATA](state, confCmsData) {
    state.confCmsData = confCmsData;
  },
  [SET_CMS_CACHE_VERSION](state, cmsCacheVersion) {
    state.cmsCacheVersion = cmsCacheVersion;
  },
  [SET_UNREAD_NOTIFICATION](state, count) {
    state.unreadNotifications = count;
  },
  [SET_CHAT_PARTICIPANT_CONFIG](state, chatParticipantConfig) {
    //console.log(`set chatParticipantconfig ${JSON.stringify(chatParticipantConfig)}`);
    state.chatParticipantConfig = chatParticipantConfig;
  },
  [SET_USER_INFO](state, userInfo) {
    //console.log(JSON.stringify(userInfo));
    state.userInfo = userInfo;
  },
  [SET_WORKSHOP_CART](state, { workshopId, workshopName, workshopPrice, slotId, slotDate, slotStartTime, slotEndTime }) {
    const workshopItemIndex = state.workshopCart.findIndex(x => x.workshopId === workshopId && x.date.getTime() === new Date(slotDate).getTime());

    let toRemove = false;
    if (workshopItemIndex !== -1) {
      if (state.workshopCart[workshopItemIndex].id === slotId) {
        toRemove = true;
      }
      state.workshopCart.splice(workshopItemIndex, 1);
    }

    if (toRemove == false) {
      state.workshopCart.push({
        id: slotId,
        workshopId: workshopId,
        name: workshopName,
        price: workshopPrice,
        date: new Date(slotDate),
        startTime: slotStartTime,
        endTime: slotEndTime
      });
    }
  },
  [SET_WORKSHOP_CART_FULL](state, cartList) {
    state.workshopCart = cartList;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
