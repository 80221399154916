<template>
  <!-- main content column -->
  <b-col cols="12" sm="12" md="8" lg="9" class="toggable-main-content pt-4" :class="isTimelineExpanded ? 'd-block d-md-none' : 'd-block'">
    <b-row>
      <!-- ESC Banner(s) -->
      <b-col :cols="postCongressMode ? 12 : 10" md="12" :offset="postCongressMode ? 0 : 2" offset-md="0">
        <EscBanners />
      </b-col>

      <!-- Content -->
      <div class="pt-5 col-12 text-center mb-4">
        <!--
        <h2><b>Congress Floorplan</b></h2>
        -->
        <b-img :src="floorplanUrl" fluid alt="Responsive image"></b-img>
      </div>

      <!-- Industry Banner(s) -->
      <b-col cols="12" md="12" offset-md="0" class="pt-1 pb-3">
        <IndustryBanners />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import openModalMixin from 'src/mixins/openModalMixin';

  import IndustryBanners from 'src/components/Banners/IndustryBanners';
  import EscBanners from 'src/components/Banners/EscBanners';

  export default {
    name: 'VenueOverview',
    mixins: [openModalMixin],
    components: {
      EscBanners,
      IndustryBanners
    },
    computed: {
      ...mapGetters(['isTimelineExpanded']),
      ...mapState({
        eventId: state => state.app.eventId,
        postCongressMode: state => state.app.postCongressMode,
        preCongressMode: state => state.app.preCongressMode,
        appVersion: state => state.app.version
      }),
      floorplanUrl() {
        return require(`@/assets/images/congress/${this.eventId}/venue/overview.png`);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';
</style>
