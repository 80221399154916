import HomeSelector from 'src/components/Selectors/HomeSelector';
import { constants } from 'src/constants';
import EventWrapper from 'src/layout/EventWrapper';
import PartnerWrapper from 'src/layout/PartnerWrapper';
import { hasExpired, processEncryptedEscID } from 'src/services/auth';
import { loadEventConfiguration } from 'src/services/eventConfig';
import { store } from 'src/store';
import { LOGIN, LOGOUT } from 'src/store/actions';
import MobileAppHappeningNow from 'src/views/MobileApp/HappeningNow';
import MobileAppInteractivity from 'src/views/MobileApp/Interactivity';
import MyProgrammeOverview from 'src/views/MyProgramme/Overview';
import NetworkingMessages from 'src/views/Networking/NetworkingMessages';
//import NetworkingOpeningSoon from 'src/views/Networking/NetworkingOpeningSoon';
import NetworkingSearch from 'src/views/Networking/NetworkingSearch';
import HealthWidget from 'src/views/Partner/Health';
import PartnerRegistrationStatus from 'src/views/Partner/RegistrationStatus';
import PartnerMediaVideo from 'src/views/Partner/Media/Video';
import PartnerMediaResources from 'src/views/Partner/Media/Resources.vue';
import ProgrammeOverview from 'src/views/Programme/Overview';
import FacultyOverview from 'src/views/Faculty/Overview';
import SimuVillage from 'src/views/SimuVillage/SimuVillage';
import AbstractOverview from 'src/views/Abstract/Overview';
import OnDemandOverview from 'src/views/OnDemand/Overview.vue';
import Schedule from 'src/views/Programme/Schedule';
import SessionDetails from 'src/views/Programme/SessionDetails';
import SpeakerDetails from 'src/views/Programme/SpeakerDetails';
import VenueOverview from 'src/views/Venue/VenueOverview';
import Home from 'src/views/Home/Home';
// import ZoomsList from 'src/views/Zooms/ZoomsList';
import MobileAppResource from 'src/views/MobileApp/Resource';
import Vue from 'vue';
import Router from 'vue-router';
import { getDefaultRoute } from './default';
import { middlewarePipeline } from './middlewarePipeline';
import { UPDATE_SCROLL_POSITION } from 'src/store/actions';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  //base: '/congress/',
  routes: [
    {
      path: '/partner',
      name: 'PartnerWrapper',
      component: PartnerWrapper,
      meta: { requireEvent: false },
      children: [
        { path: 'health', name: 'Health', component: HealthWidget },
        { path: 'registrationStatus', name: 'RegistrationStatus', component: PartnerRegistrationStatus },
        { path: 'media/video', name: 'Video', component: PartnerMediaVideo },
        {
          path: 'media/resources',
          alias: constants.ROUTE_PARTNER_MEDIA_RESOURCES,
          name: 'Resources',
          component: PartnerMediaResources,
          meta: { requireEncryptedEscID: true },
        },
      ],
    },
    {
      path: '/:eventRoute/mobile-app',
      name: 'MobileEventWrapper',
      component: EventWrapper,
      props: {
        showHeader: false,
        showFooter: false,
        enableNotifications: false,
      },
      meta: { requireEvent: true },
      children: [
        {
          path: constants.ROUTE_MOBILE_APP_PRE_HOME,
          name: 'MobileAppPreHome',
          component: HomeSelector,
          props: {
            mobileApp: true,
          },
        },
        { path: constants.ROUTE_MOBILE_APP_HAPPENING_NOW, name: 'MobileAppHappeningNow', component: MobileAppHappeningNow },
        { path: constants.ROUTE_MOBILE_APP_INTERACTIVITY, name: 'MobileAppInteractivity', component: MobileAppInteractivity },
        { path: constants.ROUTE_MOBILE_APP_RESOURCE, name: 'MobileAppResource', component: MobileAppResource },
      ],
    },
    {
      path: '/:eventRoute',
      name: 'EventWrapper',
      component: EventWrapper,
      meta: { requireEvent: true },
      children: [
        { path: '', alias: constants.ROUTE_HOME, name: 'Home', component: HomeSelector },
        { path: constants.ROUTE_LIVE, name: 'Live', component: Home },
        { path: constants.ROUTE_SCHEDULE, name: 'Schedule', component: Schedule },
        {
          path: constants.ROUTE_MY_PROGRAMME,
          name: 'MyProgramme',
          component: MyProgrammeOverview,
        },
        {
          path: constants.ROUTE_PROGRAMME,
          name: 'Programme',
          component: ProgrammeOverview,
        },
        {
          path: constants.ROUTE_FACULTY,
          name: 'Faculty',
          component: FacultyOverview,
        },
        {
          path: constants.ROUTE_SIMULATION_VILLAGE,
          name: 'Simulation Village',
          component: SimuVillage
        },
        {
          path: constants.ROUTE_ABSTRACT,
          name: 'Abstract',
          component: AbstractOverview,
        },
        {
          path: constants.ROUTE_ONDEMAND,
          name: 'OnDemand',
          component: OnDemandOverview,
        },
        { path: constants.ROUTE_SESSION_DETAILS, name: 'Session details', component: SessionDetails },
        { path: constants.ROUTE_SPEAKER_DETAILS, name: 'Speaker details', component: SpeakerDetails },
        // { path: constants.ROUTE_ZOOMS_LIST, name: 'Zooms', component: ZoomsList },
        { path: constants.ROUTE_NETWORKING_SEARCH, name: 'Networking search', component: NetworkingSearch },
        { path: constants.ROUTE_NETWORKING_MESSAGES, name: 'Networking messages', component: NetworkingMessages },
        { path: constants.ROUTE_VENUE, name: 'Venue overview', component: VenueOverview },
        {
          path: '*',
          beforeEnter: (to, from, next) => {
            next(to.params.eventRoute);
          },
        },
      ],
    },
    {
      path: '*',
      beforeEnter: (to, from, next) => {
        next(getDefaultRoute());
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      //console.log('scroll to savedPosition ' + JSON.stringify(savedPosition));
      store.dispatch(UPDATE_SCROLL_POSITION, savedPosition);
      return;
    } else if (to.hash) {
      //console.log('scroll to hash ' + to.hash);
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else if (to.path !== from.path) {
      //console.log('scroll to top');
      return { x: 0, y: 0 };
    } else if (to.query.vue === 'cards' && to.query.page && from.query.page && to.query.page !== from.query.page) {
      //console.log('scroll to search bar');
      store.dispatch(UPDATE_SCROLL_POSITION, {
        selector: '#search',
        behavior: 'smooth',
      });
      return;
    } else {
      //console.log('no scroll');
      return;
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const urlParams = new URLSearchParams(window.location.search);
  //console.log('beforeEach ' + window.location.origin + to.fullPath);

  if (to.matched.some((record) => record.meta.requireEvent)) {
    await loadEventConfiguration(to, from);
  }

  //token param, process login result
  if (urlParams.has('token')) {
    //console.log('beforeEach token detected');
    //If a login token is in query string, dispatch login action
    await store.dispatch(LOGIN, urlParams);
  }

  //if (to.matched.some((record) => record.meta.requireEncryptedEscID)) {
  if (urlParams.has('eescid')) {
    //process encrypted escId
    processEncryptedEscID(urlParams.get('eescid'));
  }

  //hpa param, process hpa check access
  /*
  if (urlParams.has('hpa')) {
    //console.log('beforeEach hpa detected')
    // If an hpa token is in query string force hasAccess refresh
    await checkQueryPlatformAccessFallbackToken(undefined, true, undefined, window.location.origin + to.fullPath);
  }

  //fca param, process force check access
  if (urlParams.has('fca')) {
    //console.log('beforeEach fca detected');
    //If an fca token is in query string force redirect to salesforce check access page
    //console.log('to.fullPath '+ to.fullPath);
    let newurl = QueryHelper.removeUrlParameter('fca', to.fullPath);
    //console.log('newurl '+ newurl);
    await checkAccess(undefined, false, window.location.origin + newurl, true);
  }
  */

  let expired = hasExpired();

  if (to.path.endsWith('/logout')) {
    expired = true;
  }

  if (expired) await store.dispatch(LOGOUT);

  const middleware = to.meta.middleware;
  if (!middleware) return next();

  const context = {
    router,
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

router.afterEach((to) => {
  const query = to.query;
  if (query.token || query.hpa || query.acl || query.eescid) {
    let nextQuery = Object.assign({}, query);
    delete nextQuery.token;
    delete nextQuery.expires;
    delete nextQuery.escId;
    delete nextQuery.email;
    delete nextQuery.title;
    delete nextQuery.title;
    delete nextQuery.firstName;
    delete nextQuery.lastName;
    delete nextQuery.hpa;
    delete nextQuery.acl;
    delete nextQuery.usr;
    delete nextQuery.fca;
    delete nextQuery.forcesso;
    delete nextQuery.eescid;

    return router.replace({
      path: to.path,
      query: nextQuery,
    });
  }
});
