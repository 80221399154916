<template>
  <fragment v-if="!isLoading || !partnerResource">
    <MobileAppChannel v-if="partnerResource" :channelId="partnerResource.id" />
  </fragment>
</template>

<script>
  import { mapState } from 'vuex';
  import { SET_IS_LOADING } from 'src/store/mutations';
  //import { redirectErrorNotFound } from 'src/services/auth';
  import MobileAppChannel from 'src/components/MobileApp/Channel';
  import { MediaApiService } from 'src/services/media-api';

  export default {
    name: 'MobileAppResource',
    components: {
      MobileAppChannel,
    },
    data() {
      return {
        partnerResource: null,
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.app.isLoading,
      }),
    },
    created() {
      /*
      //Rotate  left
      document.documentElement.style.setProperty('transform', 'rotate(-90deg)');
      document.documentElement.style.setProperty('transform-origin', 'top left');
      document.documentElement.style.setProperty('top', '100vh');
      */
      //Rotate  right
      /*
      document.documentElement.style.setProperty('transform', 'rotate(90deg)');
      document.documentElement.style.setProperty('transform-origin', 'top left');
      document.documentElement.style.setProperty('left', '100vw');
      */
    },
    beforeDestroy() {
      /*
      document.documentElement.style.removeProperty('transform');
      document.documentElement.style.removeProperty('transform-origin');
      document.documentElement.style.removeProperty('left');
      document.documentElement.style.removeProperty('top');
      */
    },
    mounted() {
      const queryString = window.location.search;
      //console.log(queryString);
      if (queryString) {
        MediaApiService.partnerGetVideo(queryString).then(({ data }) => {
          if (data) {
            //console.log(data);
            this.partnerResource = data;
            //console.log(data);
          }
          this.stopLoading();
        });
      } else {
        this.stopLoading();
        //redirectErrorNotFound();
      }
    },
    methods: {
      stopLoading() {
        this.$store.commit(SET_IS_LOADING, false);
      },      
    },
  };
</script>
