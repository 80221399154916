<template>
  <fragment>
    <!-- Header -->
    <b-col
      cols="12"
      class="pre-home__bg pt-5 d-flex justify-content-left"
      :style="{ backgroundImage: `url('${publicPath}img/events/${eventId}/pre-home-bg.png?v=${this.appVersion}')` }"
    >
      <div class="bgGradient"></div>
      <div class="eventInfo">
        <h1>
          {{ eventName }} <span>{{ locationCity }}</span>
        </h1>
        <div class="eventDate mt-3">{{ locationType }}, {{ eventStartDate | formatUnixEpocDayPart }} - {{ eventEndDate | formatUnixEpocDayPart }}</div>
        <div class="eventOrganiser">
          <small
            >Organised by the <span>{{ organiserName }}</span></small
          >
        </div>
        <div class="eventBaseline my-5">{{ homeCmsData.tagline }}</div>
        <div v-if="confCmsData.register_active">
          <a
            v-if="userInfo && !userInfo.isRegistered"
            :href="confCmsData.register_button_link.url"
            class="register__link btn btn-congress rounded-pill mt-2 with-icon"
            target="_blank"
          >
            <span class="material-icons-outlined align-middle mr-2"> confirmation_number </span> {{ confCmsData.Register_button_link_text_register }}
          </a>
          <a
            v-if="userInfo && userInfo.isRegistered && !isInternalLink(confCmsData.register_button_link_isRegistered.url )"
            :href="confCmsData.register_button_link_isRegistered.url"
            target="_blank"
            class="register__link btn btn-congress rounded-pill mt-2 with-icon"
          >
            <span class="material-icons-outlined align-middle mr-2"> checklist </span> {{ confCmsData.Register_button_link_text_isRegistered }}
          </a>
          <router-link
            v-if="userInfo && userInfo.isRegistered && isInternalLink(confCmsData.register_button_link_isRegistered.url )"
            :to="confCmsData.register_button_link_isRegistered.url "
            class="register__link btn btn-congress rounded-pill mt-2 with-icon"
          >
            <span class="material-icons-outlined align-middle mr-2"> checklist </span> {{ confCmsData.Register_button_link_text_isRegistered }}
          </router-link>

          <!-- Quiz (Mobile only) -->
          <TakeQuizzMobileWidget v-if="homeCmsData.quizz_active" />
        </div>
      </div>
    </b-col>

    <HappeningNowBlock v-if="!preCongressMode && !postCongressMode" mode="Live" />

    <!-- NEW CARDS -->
    <b-container fluid class="px-5 mb-5">
      <b-card-group deck class="eventCards">
        <b-card
          v-if="indexIndustries || indexOnDemandSessions || indexLiveSessions"
          title="Programme"
          :img-src="`${publicPath}img/events/${eventId}/scientific-programme.jpg?v=${this.appVersion}`"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-3"
        >
          <b-card-text> Browse the scientific programme and see what you don’t want to miss. </b-card-text>
          <template #footer>
            <router-link :to="`/${eventRoute}/programme`" v-slot="{ href, navigate }">
              <b-button pill :href="href" @click="navigate" variant="congress" class="stretched-link">Browse</b-button>
            </router-link>
          </template>
        </b-card>

        <b-card
          v-if="indexSpeakers"
          title="Faculty & presenters"
          :img-src="`${publicPath}img/events/${eventId}/faculty-presenter.jpg?v=${this.appVersion}`"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-3"
        >
          <b-card-text> Discover the leading experts presenting at this event. </b-card-text>
          <template #footer>
            <router-link :to="`/${eventRoute}/faculty`" v-slot="{ href, navigate }">
              <b-button pill :href="href" @click="navigate" variant="congress" class="stretched-link">Discover</b-button>
            </router-link>
          </template>
        </b-card>

        <b-card
          v-if="indexAbstracts"
          title="Abstracts"
          :img-src="`${publicPath}img/events/${eventId}/abstract.jpg?v=${this.appVersion}`"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-3"
        >
          <b-card-text> Browse through the latest science. </b-card-text>
          <template #footer>
            <router-link :to="`/${eventRoute}/abstract`" v-slot="{ href, navigate }">
              <b-button pill :href="href" @click="navigate" variant="congress" class="stretched-link">Start now</b-button>
            </router-link>
          </template>
        </b-card>

        <b-card
          title="My programme"
          :img-src="`${publicPath}img/events/${eventId}/schedule-overview.jpg?v=${this.appVersion}`"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-3"
        >
          <b-card-text> Your bookmarked sessions are all here. </b-card-text>
          <template #footer>
            <router-link :to="`/${eventRoute}/my-programme`" v-slot="{ href, navigate }">
              <b-button pill :href="href" @click="navigate" variant="congress" class="stretched-link">Access</b-button>
            </router-link>
          </template>
        </b-card>
      </b-card-group>
    </b-container>

    <!-- Register -->
    <RegisterPromoWidget class="phr" />

    <!-- Industry Banner(s) -->
    <!--
    <b-col cols="12" class="pb-4">
      <EscBanners />
    </b-col>
    -->

    <!-- NEW CARDS FROM SLIDER -->
    <AuthoredSlidesBlock :homeCmsData="homeCmsData" />
  </fragment>
</template>

<script>
  import { mapState } from 'vuex';
  import TakeQuizzMobileWidget from 'src/components/Widgets/TakeQuizzMobileWidget';
  import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';
  // import EscBanners from 'src/components/Banners/EscBanners';
  import AuthoredSlidesBlock from 'src/components/Blocks/AuthoredSlidesBlock.vue';
  import HappeningNowBlock from 'src/components/Blocks/HappeningNowBlock.vue';
  import { HtmlHelper } from 'src/utils/html-helper';

  export default {
    name: 'PreHomeContentV2',
    components: {
      // EscBanners,
      TakeQuizzMobileWidget,
      RegisterPromoWidget,
      AuthoredSlidesBlock,
      HappeningNowBlock,
    },
    data() {
      return {
        carouselContentComponent: '',
        speakerSearchQuery: '',
        abstractSearchQuery: '',
        publicPath: process.env.BASE_URL,
      };
    },
    computed: {
      ...mapState({
        appVersion: (state) => state.app.version,
        eventId: (state) => state.app.eventId,
        eventRoute: (state) => state.app.eventRoute,
        eventStartDate: (state) => state.app.eventStartDate,
        eventEndDate: (state) => state.app.eventEndDate,
        postCongressMode: (state) => state.app.postCongressMode,
        homeCmsData: (state) => state.app.homeCmsData,
        industryStandsSearch: (state) => state.app.industryStandsSearch,
        indexAbstracts: (state) => state.app.indexAbstracts,
        indexIndustries: (state) => state.app.indexIndustries,
        indexLiveSessions: (state) => state.app.indexLiveSessions,
        indexOnDemandSessions: (state) => state.app.indexOnDemandSessions,
        indexSpeakers: (state) => state.app.indexSpeakers,
        confCmsData: (state) => state.app.confCmsData,
        location: (state) => state.app.location,
        locationType: (state) => state.app.locationType,
        organiserName: (state) => state.app.organiserName,
        eventName: (state) => state.app.eventName,
        locationCity: (state) => state.app.locationCity,
        userInfo: (state) => state.app.userInfo,
        preCongressMode: (state) => state.app.preCongressMode,
        nbTitles() {
          let nb = 1;
          if (this.indexAbstracts) nb++;
          if (this.indexIndustries || this.indexOnDemandSessions || this.indexLiveSessions) nb++;
          if (this.indexSpeakers) nb++;
          return nb;
        },
        tileSize() {
          return 12 / this.nbTitles;
        },
        titlesContainerSize() {
          return 2 * this.nbTitles;
        },
      }),
      /*
      loader() {
        return () => import(`./${this.eventId}/CarouselContent.vue`);
      }
      */
    },
    /*
    created() {
      this.loader().then(() => {
        this.carouselContentComponent = () => this.loader();
      });
    },
    */
    async mounted() {
      // this.speakerSearchQuery = await this.$lzma.compress({ text: '', docType: 'Speaker', country: [], page: 1 });
      // this.abstractSearchQuery = await this.$lzma.compress({ text: '', docType: 'Abstract', country: [], page: 1 });
      this.speakerSearchQuery = `docType=Speaker&page=1`;
      this.abstractSearchQuery = `docType=Abstract&page=1`;
    },
    methods: {
      isInternalLink(url) {
        return HtmlHelper.isInternalLink(url);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .pre-home__bg {
    // background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: no-repeat;
    background-color: var(--congress-dark);
    min-height: 325px;
    background-position: top center;
  }

  @media (max-width: 1200px) {
    .pre-home__bg {
      // background: no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background: no-repeat;
      background-color: var(--congress-dark);
      min-height: 325px;
      background-position: top center;
    }

    /*
    ::v-deep .carousel-item {
      background: #fff !important;
    }

    @media (max-width: 992px) {
      ::v-deep .carousel-indicators {
        justify-content: flex-end !important;
      }
    }
    */

    @media (max-width: 1200px) {
      .pre-home__bg {
        min-height: 550px;
      }
    }

    @media (min-width: 1200px) {
      .pre-home__bg {
        min-height: 325px;
      }
    }
  }

  @media (min-width: 1200px) {
    .pre-home__bg {
      min-height: 325px;
      background-size: 100%;
    }
  }

  .pre-home__quiz__wrapper {
    text-align: center;
    font-size: 0.9rem;
    color: white;
  }

  /*.pre-home__countdown__wrapper {
    color: white;
  }*/

  .pre-home__slider__wrapper {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    margin: 0 auto -80px auto;
    position: relative;
    top: -116px;
    max-width: 1200px;

    /*
    &::v-deep .carousel-inner {
      border-radius: 6px;
    }

    &::v-deep .carousel-caption {
      top: 0;
      left: 0;
      bottom: 0;
      max-width: 30%;
      padding: 1.5rem;
      color: black;
      text-align: left;
      background: white;
      border-radius: 4px 0 0 4px;

      & > .btn {
        font-weight: bold;
      }
    }

    &::v-deep .carousel-indicators {
      right: 0;
      bottom: 0;
      left: 0;
      justify-content: left;
      padding-left: 1rem;
      padding-bottom: 1rem;
      margin: 0;

      & li {
        background-color: var(--congress);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        opacity: 0.35;

        &.active {
          opacity: 1;
        }
      }
    }

    &::v-deep .carousel-caption-title {
      color: var(--congress);
      font-weight: bold;
      margin-bottom: 1rem;
    }
        */
  }

  .pre-home__cards__wrapper {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    margin: 0 1rem;
    background: #fff;

    & div.pre-home__card__wrapper:not(:last-child) {
      border-right: solid 2px $grey-lighter;
    }
  }

  .pre-home__card__wrapper {
    cursor: pointer;
    padding: 2.5rem !important;

    &:hover {
      background: var(--congress-lighter);
    }
  }

  .card__icon {
    /*opacity: 0.4;*/
    padding-bottom: 1rem;
  }

  .card__title {
    font-size: 1.4rem;
    /*max-width: 200px;*/
  }

  .card__description {
    font-size: 0.9rem;
    color: $grey;
    padding: 0.5rem 0;
    /*max-width: 200px;*/
  }

  .card__link {
    margin-top: auto;
    font-size: 0.9rem;
    color: var(--congress);
    text-decoration: none;

    &:hover,
    :focus {
      color: var(--congress-dark);
    }
  }

  .pre-home__card__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .pre-home__cards__wrapper div.pre-home__card__wrapper:not(:last-child) {
      border-right: 0;
      border-bottom: solid 2px #e2e2e2;
    }
  }

  /* FIX SLIDER */

  /*
  :v-deep .carousel-caption-title {
    color: var(--congress);
    font-weight: 700;
    margin-bottom: 1rem;
  }
 */
  /*
  @media (max-width: 992px) {
  
    ::v-deep .carousel-item img {
      flex: 0 0 100%;
      max-width: 100%;
      width: inherit !important;
      float: inherit;
      position: absolute;
      -o-object-fit: cover;
      object-fit: cover;
      height: -webkit-fill-available;
    }

    ::v-deep .carousel-caption {
      flex: 0 0 100%;
      max-width: 100% !important;
      right: 0;
      background-color: hsla(0, 0%, 100%, 0.8) !important;
      position: relative;
    }
  }

  @media (min-width: 992px) {
    ::v-deep .carousel-item img {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      width: inherit !important;
      float: right;
    }

    ::v-deep .carousel-caption {
      flex: 0 0 33.33333%;
      max-width: 33.33333% !important;
    }
  }
*/
</style>
