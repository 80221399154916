<template>
  <fragment>
    <!-- Header -->
    <b-col
      cols="12"
      class="pre-home__bg pt-2  d-flex justify-content-center"
      :style="{ backgroundImage: `url('${publicPath}img/events/${eventId}/pre-home-bg.png?v=${this.appVersion}')` }"
    >
      <div class="pre-home__countdown__wrapper">
        <!-- Countdown -->
        <PreHomeCountDownWidget v-if="homeCmsData.countdown_active" :message="homeCmsData.countdown_text" />

        <!-- Quiz (Mobile only) -->
        <TakeQuizzMobileWidget v-if="homeCmsData.quizz_active" />
      </div>
    </b-col>

    <!-- Slider -->
    <b-col cols="12" v-if="homeCmsData.slider_blocks && homeCmsData.slider_blocks.length > 0">
      <div class="pre-home__slider__wrapper">
        <b-carousel id="pre-home__slider__ref" fade :interval="7000" indicators img-width="1200" img-height="400" background="#e0e0e0" style="border-radius: 24px">
          <b-carousel-slide v-for="sliderBlock in homeCmsData.slider_blocks" :key="sliderBlock._uid" :img-src="sliderBlock.slider_image.filename">
            <h4 class="carousel-caption-title">
              {{ sliderBlock.slider_title }}
            </h4>
            <p v-html="$options.filters.formatStoryRichText(sliderBlock.slider_text)"></p>
            <b-button
              v-if="false"
              variant="outline-congress"
              :to="sliderBlock.slider_button_link[0].link_internal ? sliderBlock.slider_button_link[0].link_value : null"
              :href="!sliderBlock.slider_button_link[0].link_internal ? sliderBlock.slider_button_link[0].link_value : null"
              :target="!sliderBlock.slider_button_link[0].link_internal && sliderBlock.slider_button_link[0].link_new_tab ? '_blank' : '_self'"
            >
              {{ sliderBlock.slider_button_link[0].link_text }}
            </b-button>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-col>

    <!-- Register -->
    <RegisterPromoWidget v-if="false" class="phr" />

    <!-- Cards -->
    <b-col v-if="false" cols="12" :lg="titlesContainerSize" class="phr mb-5">
      <b-row class="pre-home__cards__wrapper no-gutters text-center" align-h="center">
        <b-col cols="12" :md="tileSize" class="pre-home__card__wrapper" v-if="indexIndustries || indexOnDemandSessions || indexLiveSessions">
          <router-link :to="`/${eventRoute}/programme`" class="pre-home__card__link">
            <div class="card__icon">
              <svg width="38" height="38" viewBox="0 0 38 38" class="fill-congress">
                <use xlink:href="~@/assets/images/platform/Session-Icon-BLK.svg#Layer_1" />
              </svg>
            </div>
            <div class="card__title">Programme Search</div>
            <div class="card__description">Browse the scientific programme and see what you don’t want to miss.</div>
            <span class="card__link">Start now<i class="pl-1 las la-2x la-t-plus-3 la-arrow-right"></i> </span>
          </router-link>
        </b-col>

        <b-col cols="12" :md="tileSize" class="pre-home__card__wrapper" v-if="indexSpeakers">
          <router-link :to="`/${eventRoute}/programme?text=&${speakerSearchQuery}`" class="pre-home__card__link">
            <div class="card__icon">
              <svg width="38" height="38" viewBox="0 0 38 38" class="fill-congress">
                <use xlink:href="~@/assets/images/platform/Speaker-Icon-BLK.svg#Layer_1" />
              </svg>
            </div>
            <div class="card__title">Faculty & Presenters</div>
            <div class="card__description">Discover the leading experts presenting at this event.</div>
            <span class="card__link">Start now<i class="pl-1 las la-2x la-t-plus-3 la-arrow-right"></i> </span>
          </router-link>
        </b-col>

        <b-col cols="12" :md="tileSize" class="pre-home__card__wrapper" v-if="indexAbstracts">
          <router-link :to="`/${eventRoute}/programme?text=&${abstractSearchQuery}`" class="pre-home__card__link">
            <div class="card__icon">
              <svg width="38" height="38" viewBox="0 0 38 38" class="fill-congress">
                <use xlink:href="~@/assets/images/platform/Abstract-Icon-BLK.svg#Layer_1" />
              </svg>
            </div>
            <div class="card__title">Abstracts</div>
            <div class="card__description">Browse through the latest science.</div>
            <span class="card__link">Start now<i class="pl-1 las la-2x la-t-plus-3 la-arrow-right"></i> </span>
          </router-link>
        </b-col>

        <b-col cols="12" :md="tileSize" class="pre-home__card__wrapper">
          <router-link :to="`/${eventRoute}/schedule`" class="pre-home__card__link">
            <div class="card__icon">
              <svg width="38" height="38" viewBox="0 0 38 38" class="fill-congress">
                <use xlink:href="~@/assets/images/platform/Calendar-Icon-BLK.svg#Layer_1" />
              </svg>
            </div>
            <div class="card__title">Schedule Overview</div>
            <div class="card__description">See what’s planned by day in each channel.</div>
            <span class="card__link">Start now<i class="pl-1 las la-2x la-t-plus-3 la-arrow-right"></i> </span>
          </router-link>
        </b-col>
      </b-row>
    </b-col>
  </fragment>
</template>

<script>
  import { mapState } from 'vuex';
  import PreHomeCountDownWidget from 'src/components/Widgets/PreHomeCountDownWidget';
  import TakeQuizzMobileWidget from 'src/components/Widgets/TakeQuizzMobileWidget';
  import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';

  export default {
    name: 'MobileAppPreHome',
    components: {
      PreHomeCountDownWidget,
      TakeQuizzMobileWidget,
      RegisterPromoWidget
    },
    data() {
      return {
        carouselContentComponent: '',
        speakerSearchQuery: '',
        abstractSearchQuery: '',
        publicPath: process.env.BASE_URL
      };
    },
    computed: {
      ...mapState({
        appVersion: state => state.app.version,
        eventId: state => state.app.eventId,
        eventRoute: state => state.app.eventRoute,
        eventStartDate: state => state.app.eventStartDate,
        postCongressMode: state => state.app.postCongressMode,
        homeCmsData: state => state.app.homeCmsData,
        industryStandsSearch: state => state.app.industryStandsSearch,
        indexAbstracts: state => state.app.indexAbstracts,
        indexIndustries: state => state.app.indexIndustries,
        indexLiveSessions: state => state.app.indexLiveSessions,
        indexOnDemandSessions: state => state.app.indexOnDemandSessions,
        indexSpeakers: state => state.app.indexSpeakers,
        nbTitles() {
          let nb = 1;
          if (this.indexAbstracts) nb++;
          if (this.indexIndustries || this.indexOnDemandSessions || this.indexLiveSessions) nb++;
          if (this.indexSpeakers) nb++;
          return nb;
        },
        tileSize() {
          return 12 / this.nbTitles;
        },
        titlesContainerSize() {
          return 2 * this.nbTitles;
        }
      })
    },

    async mounted() {
      //this.speakerSearchQuery = await this.$lzma.compress({ text: '', docType: 'Speaker', country: [], page: 1 });
      //this.abstractSearchQuery = await this.$lzma.compress({ text: '', docType: 'Abstract', country: [], page: 1 });
      this.speakerSearchQuery = `docType=Speaker&page=1`
      this.abstractSearchQuery = `docType=Abstract&page=1`
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .pre-home__bg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: no-repeat;
    background-color: var(--congress-dark);
    min-height: 325px;
    background-position: top center;
  }

  @media (max-width: 1200px) {
    .pre-home__bg {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background: no-repeat;
      background-color: var(--congress-dark);
      min-height: 325px;
      background-position: top center;
    }

    ::v-deep .carousel-item {
      background: #fff !important;
    }

    @media (max-width: 992px) {
      ::v-deep .carousel-indicators {
        justify-content: flex-end !important;
      }
    }

    @media (max-width: 1200px) {
      .pre-home__bg {
        min-height: 400px;
      }
    }

    @media (min-width: 1200px) {
      .pre-home__bg {
        min-height: 325px;
      }
    }
  }

  @media (min-width: 1200px) {
    .pre-home__bg {
      min-height: 325px;
      background-size: 100%;
    }
  }

  .pre-home__quiz__wrapper {
    text-align: center;
    font-size: 0.9rem;
    color: white;
  }

  .pre-home__countdown__wrapper {
    color: white;
  }

  .pre-home__slider__wrapper {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    margin: 0 auto -80px auto;
    position: relative;
    top: -116px;
    max-width: 1200px;

    &::v-deep .carousel-inner {
      border-radius: 6px;
    }

    &::v-deep .carousel-caption {
      top: 0;
      left: 0;
      bottom: 0;
      max-width: 30%;
      padding: 1.5rem;
      color: black;
      text-align: left;
      background: white;
      border-radius: 4px 0 0 4px;

      & > .btn {
        font-weight: bold;
      }
    }

    &::v-deep .carousel-indicators {
      right: 0;
      bottom: 0;
      left: 0;
      justify-content: left;
      padding-left: 1rem;
      padding-bottom: 1rem;
      margin: 0;

      & li {
        background-color: var(--congress);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        opacity: 0.35;

        &.active {
          opacity: 1;
        }
      }
    }

    &::v-deep .carousel-caption-title {
      color: var(--congress);
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .pre-home__cards__wrapper {
    border-radius: 6px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    margin: 0 1rem;
    background: #fff;

    & div.pre-home__card__wrapper:not(:last-child) {
      border-right: solid 2px $grey-lighter;
    }
  }

  .pre-home__card__wrapper {
    cursor: pointer;
    padding: 2.5rem !important;

    &:hover {
      background: var(--congress-lighter);
    }
  }

  .card__icon {
    padding-bottom: 1rem;
  }

  .card__title {
    font-size: 1.4rem;
  }

  .card__description {
    font-size: 0.9rem;
    color: $grey;
    padding: 0.5rem 0;
  }

  .card__link {
    margin-top: auto;
    font-size: 0.9rem;
    color: var(--congress);
    text-decoration: none;

    &:hover,
    :focus {
      color: var(--congress-dark);
    }
  }

  .pre-home__card__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .pre-home__cards__wrapper div.pre-home__card__wrapper:not(:last-child) {
      border-right: 0;
      border-bottom: solid 2px #e2e2e2;
    }
  }

  /* FIX SLIDER */

  :v-deep .carousel-caption-title {
    color: var(--congress);
    font-weight: 700;
    margin-bottom: 1rem;
  }

  @media (max-width: 992px) {
    ::v-deep .carousel-item img {
      flex: 0 0 100%;
      max-width: 100%;
      width: inherit !important;
      float: inherit;
      position: absolute;
      -o-object-fit: cover;
      object-fit: cover;
      height: -webkit-fill-available;
    }

    ::v-deep .carousel-caption {
      flex: 0 0 100%;
      max-width: 100% !important;
      right: 0;
      background-color: hsla(0, 0%, 100%, 0.8) !important;
      position: relative;
    }
  }

  @media (min-width: 992px) {
    ::v-deep .carousel-item img {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      width: inherit !important;
      float: right;
    }

    ::v-deep .carousel-caption {
      flex: 0 0 33.33333%;
      max-width: 33.33333% !important;
    }
  }
</style>
