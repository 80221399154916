<template>
  <b-col cols="12" class="mt-3 mb-5" v-if="!postCongressMode && confCmsData.register_active">
    <div
      v-if="!userInfo.isRegistered"
      class="register__wrapper col-10 col-md-8 col-lg-6 col-xl-4"
      :style="{ backgroundImage: `url('${publicPath}img/events/${eventId}/banner-register.png?v=${this.appVersion}')` }"
    >
      <div class="CTA__Text">
        {{ confCmsData.register_text }}
      </div>
      <div class="CTA__Action">
        <span class="register__link__wrapper">
          <a :href="confCmsData.register_button_link.url" class="register__link btn" target="_blank">
            {{ confCmsData.register_button_text }}
          </a>
        </span>
      </div>
    </div>
    <div
      v-if="userInfo.isRegistered"
      class="register__wrapper col-10 col-md-8 col-lg-6 col-xl-4"
      :style="{ backgroundImage: `url('${publicPath}img/events/${eventId}/banner-register.png?v=${this.appVersion}')` }"
    >
      <div class="CTA__Text">
        {{ confCmsData.register_text_isRegistered }}
      </div>
      <div class="CTA__Action">
        <span class="register__link__wrapper">
          <a
            v-if="!isInternalLink(confCmsData.register_button_link_isRegistered.url)"
            :href="confCmsData.register_button_link_isRegistered.url"
            class="register__link btn"
            target="_blank"
          >
            {{ confCmsData.register_button_text_isRegistered }}
          </a>
          <router-link v-else :to="confCmsData.register_button_link_isRegistered.url" class="register__link btn">
            {{ confCmsData.register_button_text_isRegistered }}
          </router-link>
        </span>
      </div>
    </div>
  </b-col>
</template>

<script>
  import { mapState } from 'vuex';
  import { HtmlHelper } from 'src/utils/html-helper';

  export default {
    name: 'RegisterPromoWidget',
    computed: {
      ...mapState({
        postCongressMode: (state) => state.app.postCongressMode,
        confCmsData: (state) => state.app.confCmsData,
        appVersion: (state) => state.app.version,
        eventId: (state) => state.app.eventId,
        userInfo: (state) => state.app.userInfo,
      }),
    },
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    mounted() {
      // If needed...
    },
    methods: {
      isInternalLink(url) {
        return HtmlHelper.isInternalLink(url);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .register__wrapper {
    background: $grey-lighter;
    border-radius: 10px;
    padding: 1.5rem;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 550px;
    margin: 0 auto;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    text-align: left;
  }

  .register__link {
    text-decoration: none;
  }

  /*.register__link__wrapper {
    float: right;
  }*/

  .register__link {
    background: var(--congress);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 0.95rem;
    text-decoration: none;

    &:hover,
    :focus {
      color: var(--congress-lighter);
    }
  }

  .CTA__Text {
    flex: 0 1 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .CTA__Action {
    flex: 0 0 100%;
    text-align: center;
  }

  @media (min-width: 600px) {
    .CTA__Action {
      flex: 0 0 auto;
    }

    .CTA__Text {
      flex: 1 1 0%;
      padding-right: 25px;
      text-align: left;
      margin-bottom: 0;
    }
  }

  .CTA__Action a {
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    padding: 13px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    opacity: 1;
    margin: 0px;
    font-size: 15px;
    line-height: 1;
    background: var(--congress);
    color: rgb(255, 255, 255);
  }
</style>
