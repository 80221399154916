<template>
  <div id="talkjs-container" :class="{ 'd-none': hidden }" style="width: 90%; margin: 30px; height: 500px">
    Loading chat...
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import Talk from 'talkjs';
  import { MediaApiService } from 'src/services/media-api';
  import { SET_UNREAD_NOTIFICATION } from 'src/store/mutations';

  export default {
    name: 'NetworkingChat',
    props: {
      hidden: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        me: null,
        session: null,
        inbox: null
      };
    },
    computed: {
      ...mapGetters(['currentUser']),
      ...mapState({
        eventId: state => state.app.eventId,
        eventBaseTitle: state => state.app.eventBaseTitle
      })
    },
    async mounted() {
      const { data } = await MediaApiService.getSignature();
      const signature = data?.signature;
      if (!signature) throw new Error('No signature');

      this.me = new Talk.User(this.currentUser?.escId);

      let recipient = null;

      const recipientId = this.$route.query['r'];
      if (recipientId && Number.isInteger(parseInt(recipientId)) && recipientId !== this.currentUser?.escId) {
        recipient = new Talk.User(recipientId);

        let query = Object.assign({}, this.$route.query);
        delete query.r;
        this.$router.replace({ query: query });
      }

      let _this = this;

      Talk.ready.then(function() {
        _this.session = new Talk.Session({
          appId: process.env.VUE_APP_TALKJS_APP_ID,
          me: _this.me,
          signature: signature
        });

        _this.session.unreads.on('change', _this.onUnreadsChange);

        if (recipient != null && recipient.id != _this.me.id) {
          let conversation = _this.session.getOrCreateConversation(Talk.oneOnOneId(_this.me, recipient));
          conversation.setParticipant(_this.me);
          conversation.setParticipant(recipient);

          _this.inbox = _this.session.createInbox({ selected: conversation, useBrowserHistory: false });
        } else {
          _this.inbox = _this.session.createInbox({ useBrowserHistory: false });
        }

        _this.inbox.mount(document.getElementById('talkjs-container'));
      });
    },
    beforeDestroy() {
      if (this.session) this.session.destroy();
      if (this.inbox) this.inbox.destroy();
    },
    methods: {
      onUnreadsChange(unreadConversations) {
        const amountOfUnreads = unreadConversations.length;

        // Setting document title...
        document.title = `${amountOfUnreads > 0 ? `(${amountOfUnreads}) ` : ''}${this.eventBaseTitle}`;

        // Dispatching amountOfUnreads in store...
        this.$store.commit(SET_UNREAD_NOTIFICATION, amountOfUnreads);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';
</style>
