export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CHECK_AUTH = 'checkAuth';

export const TOGGLE_TIMELINE = 'toggleTimeline';

export const ENABLE_POOLING = 'enablePooling';
export const DISABLE_POOLING = 'disablePooling';

export const OPEN_MEDIA_MODAL = 'openMediaModal';
export const CLOSE_MEDIA_MODAL = 'closeMediaModal';

export const OPEN_CHANNEL_MODAL = 'openChannelModal';
export const CLOSE_CHANNEL_MODAL = 'closeChannelModal';

export const FETCH_BANNERS = 'fetchBanners';

export const FETCH_FAVS = 'fetchFavs';
export const ADD_FAV = 'addFav';
export const REM_FAV = 'remFav';

export const UPDATE_SCROLL_POSITION = 'updateScrollPosition';

export const UPDATE_PARTNER_USER = 'updatePartnerUser';