import { UPDATE_SCROLL_POSITION } from 'src/store/actions';
import { SET_SCROLL_POSITION } from 'src/store/mutations';

const initialState = { position: null };

const state = { ...initialState };

const getters = {
    scrollPosition(state) {
        return state.position;
    }
};

const actions = {
    [UPDATE_SCROLL_POSITION]({ commit },position) {
        commit(SET_SCROLL_POSITION, position);
    },
};

const mutations = {
    [SET_SCROLL_POSITION](state, position) {
        state.position = position;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
