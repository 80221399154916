<template>
  <div>
    <!--:title="`Live & Upcoming (${liveAndUpcomingFavs.length})`"-->
    <b-tabs v-if="this.favs.length > 0" content-class="mt-3 mb-5" fill>
      <b-tab v-if="liveAndUpcomingFavs.length > 0">
        <ListSessionBlock :items="liveAndUpcomingFavs" />
      </b-tab>
      <b-tab v-if="onDemandFavs.length > 0">
        <template #title>
          <b-card-title
            ><span class="tab__title"
              >On Demand Sessions <span class="tab__title__count">({{ onDemandFavs.length }})</span></span
            ></b-card-title
          >
        </template>

        <ListSessionBlock :items="onDemandFavs" />
      </b-tab>
    </b-tabs>
    <div v-else>
    <h5 style="text-align: center">You haven't added anything to your programme yet.</h5>
    <h5 style="text-align: center">Browse the scientific sessions, and bookmark your favourites.</h5>
  </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ListSessionBlock from 'src/components/Blocks/ListSessionBlock';

  export default {
    name: 'OverviewTabs',
    components: {
      ListSessionBlock
    },
    props: {},
    watch: {},
    data() {
      return {};
    },
    computed: {
      ...mapState({
        eventId: state => state.app.eventId,
        favs: state => state.auth.favs
      }),
      liveAndUpcomingFavs() {
        let result = this.favs.filter(fav => !fav.session.isOnDemand);
        //console.log('liveAndUpcomingFavs ' + JSON.stringify(result));
        return result;
      },
      onDemandFavs() {
        let result = this.favs.filter(fav => fav.session.isOnDemand);
        //console.log('onDemandFavs ' + JSON.stringify(result));
        return result;
      }
    },
    mounted() {},
    methods: {}
  };
</script>
<style lang="scss" scoped>
  @import '~@/styles/theme';

  .tab__title__count {
    font-size: 0.75em;
  }
  
</style>
